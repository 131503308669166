<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_tansportOrderUpdate" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelectedText.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        readonly
                        v-model="suppliersSelectedText"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        persistent-hint
                        margin-bottom="0"
                        :hint="setSrhSupplier()"
                        pa-3
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedText }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    readonly
                    v-model="suppliersSelectedText"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    :hint="setSrhSupplier()"
                    pa-3
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日-->
            <div class="date-style">
              <v-text-field
                :readonly="isDisabledDelBtn"
                outlined
                dense
                v-model="orderDate"
                :label="$t('label.lbl_orderDate')"
                @change="changeDate($event, 'order')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div v-if="isDisabledDelBtn == false">
              <div class="calender-btn-area">
                <v-menu
                  v-model="orderDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="orderDateCal"
                    @input="orderDateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  id="btn-yesterday"
                  class="day-ctrl-btn"
                  @click="prevDate('order')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  id="btn-tomorow"
                  class="day-ctrl-btn"
                  @click="nextDate('order')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 輸送担当営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_transportOffice')"
                :hint="setSrhOffice()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changeOffice"
                :search-input.sync="officeInput"
                :readonly="isDisabledDelBtn"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- 空白 -->
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 業務No-->
            <div class="search-autocomplete first-search-item">
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="bussinessNoSelectedText"
                  :label="$t('label.lbl_billingBussinessNo')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  :hint="setBussinessNo()"
                  pa-3
                ></v-text-field>
              </div>
            </div>
            <span class="item-spacer">&nbsp;</span>
            <!-- 配車計画作成-->
            <div class="text-label" style="margin-left: 15px">
              {{ $t("label.lbl_dispatchPlanCreat") }}
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 対象/対象外 -->
            <v-btn-toggle
              mandatory
              v-model="isTarget"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
              @change="changeIsTarget"
            >
              <v-btn
                class="paid-btn"
                v-for="item in targetDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledDelBtn"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 非公開/公開 -->
            <v-btn-toggle
              mandatory
              v-model="isPrivate"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn
                class="paid-btn"
                v-for="item in privateDivList"
                :key="item.value"
                :value="item.value"
                v-bind:disabled="isDisabledPrivate || isDisabledDelBtn"
                small
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!--集荷方法区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupDivSelected"
                :items="pickupDivList"
                :label="$t('label.lbl_pickupDiv')"
                class="txt-single"
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                :readonly="isDisabledDelBtn"
                @focus="focusPickupDiv"
                @change="changePickupDiv"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 集荷元 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPointSelected"
                :items="pickupPointList"
                :label="$t('label.lbl_pickupPoint')"
                :hint="setSrhPickup()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changePickup"
                :search-input.sync="pickupInput"
                :readonly="isDisabledDelBtn"
                :error-messages="pickupPointAlertMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 拠点登録 -->
            <v-btn color="primary" class="api-btn" disabled>
              {{ $t("btn.btn_deliveryPointInsert") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集荷予定日-->
            <div class="date-style">
              <v-text-field
                :readonly="isDisabledDelBtn"
                outlined
                dense
                v-model="pickupScheduleDate"
                :label="$t('label.lbl_pickupScheduleDate')"
                @change="changeDate($event, 'pickup')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div v-if="isDisabledDelBtn == false">
              <div class="calender-btn-area">
                <v-menu
                  v-model="pickupScheduleDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="pickupScheduleDateCal"
                    @input="pickupScheduleDateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  id="btn-yesterday"
                  class="day-ctrl-btn"
                  @click="prevDate('pickup')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  id="btn-tomorow"
                  class="day-ctrl-btn"
                  @click="nextDate('pickup')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <span v-if="isDisabledDelBtn" class="item-spacer">&nbsp;</span>
            <!-- 集荷時間From-->
            <div>
              <v-text-field
                v-model="pickupScheduleTimeFrom"
                :type="type"
                style="padding: 0px; margin: 0px; max-width: 100px"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
                :readonly="isDisabledDelBtn"
              ></v-text-field>
            </div>
            <!-- ハイフン -->
            <span class="item-spacer">&nbsp;－&nbsp;</span>
            <!-- 集荷時間To-->
            <div>
              <v-text-field
                v-model="pickupScheduleTimeTo"
                :type="type"
                style="padding: 0px; margin: 0px; max-width: 100px"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
                :error-messages="pickupScheduleTimeAlertMessage"
                :readonly="isDisabledDelBtn"
              ></v-text-field>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row class="search-row" style="margin-top: 10px">
            <!--配達方法区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryDivSelected"
                :items="deliveryDivList"
                :label="$t('label.lbl_deliveryDiv')"
                class="txt-single"
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                :readonly="isDisabledDelBtn"
                :error-messages="deliveryDivAlertMessage"
                @change="changeDeliveryDiv"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 配達先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPointSelected"
                :items="deliveryPointList"
                :label="$t('label.lbl_deliveryPoint')"
                :hint="setSrhDelivery()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                @change="changeDelivery"
                :search-input.sync="deliveryInput"
                :error-messages="deliveryPointAlertMessage"
                :readonly="isDisabledDelBtn"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 拠点登録 -->
            <v-btn color="primary" class="api-btn" disabled>
              {{ $t("btn.btn_deliveryPointInsert") }}
            </v-btn>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 配達予定日-->
            <div class="date-style">
              <v-text-field
                :readonly="isDisabledDelBtn"
                outlined
                dense
                v-model="deliveryScheduleDate"
                :label="$t('label.lbl_deliveryScheduleDate')"
                @change="changeDate($event, 'delivery')"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
                :error-messages="deliveryScheduleDateAlertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div v-if="isDisabledDelBtn == false">
              <div class="calender-btn-area">
                <v-menu
                  v-model="deliveryScheduleDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="deliveryScheduleDateCal"
                    @input="deliveryScheduleDateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  id="btn-yesterday"
                  class="day-ctrl-btn"
                  @click="prevDate('delivery')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  id="btn-tomorow"
                  class="day-ctrl-btn"
                  @click="nextDate('delivery')"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <span v-if="isDisabledDelBtn" class="item-spacer">&nbsp;</span>
            <!-- 配達時間From-->
            <div>
              <v-text-field
                v-model="deliveryScheduleTimeFrom"
                :type="type"
                style="padding: 0px; margin: 0px; max-width: 100px"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
                :readonly="isDisabledDelBtn"
              ></v-text-field>
            </div>
            <!-- ハイフン -->
            <span class="item-spacer">&nbsp;－&nbsp;</span>
            <!-- 配達時間To-->
            <div>
              <v-text-field
                v-model="deliveryScheduleTimeTo"
                :type="type"
                style="padding: 0px; margin: 0px; max-width: 100px"
                clear-icon="mdi-close-circle"
                :clearable="!isDisabledDelBtn"
                :error-messages="deliveryScheduleTimeAlertMessage"
                :readonly="isDisabledDelBtn"
              ></v-text-field>
            </div>
          </v-row>
          <!-- 4段目 -->
          <v-row>
            <!-- お客様問合せ番号 -->
            <div class="search-row-exeBtn first-search-item">
              <div class="add-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="inquiryNumber"
                  :label="$t('label.lbl_inquiryNumber')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  :clearable="!isDisabledDelBtn"
                  :readonly="isDisabledDelBtn"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <div class="btn-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 追加ボタン-->
                <v-btn
                  id="other-btn"
                  class="other-btn"
                  :disabled="isDisabledAddBtn"
                  value="1"
                  @click="add"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <div class="btn-area">
                <!-- 修正登録ボタン-->
                <v-btn
                  class="post-btn"
                  :disabled="isDisabledUpdateBtn"
                  value="1"
                  v-on:click="trnAdd"
                >
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>

                <!-- 伝票削除ボタン-->
                <v-btn class="post-btn" :disabled="isDisabledDelBtn" value="2" v-on:click="trnDel">
                  {{ $t("btn.btn_orderdelete") }}
                </v-btn>

                <!-- メッセージダイアログ-->
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addTransport"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid style="padding-bottom: 0px">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="480px"
          >
            <template v-slot:[`item.productName`]="{ item, index }" style="padding: 0 5px">
              <!-- 品名-->
              <div v-if="!item.isEditable">
                {{ item.productName }}
              </div>
              <div v-else>
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.productName"
                  maxlength="100"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 200px"
                  :ref="'cursorProcd' + index"
                  :rules="[$inputRules.isRequiredRow(headerItems, item)]"
                  :error-messages="item.listItemsErrorMsg"
                />
              </div>
            </template>

            <template v-slot:[`item.packing`]="{ item }">
              <!-- 荷姿-->
              <div v-if="!item.isEditable">
                {{ getPackingText(item.packing) }}
              </div>
              <div v-else>
                <v-autocomplete
                  v-model="item.packing"
                  :items="packingNacCdList"
                  :search-input.sync="item.search"
                  dense
                  :error-messages="item.listItemsErrorMsg"
                >
                  <option disabled value="item.packing"></option>
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <template v-slot:[`item.weight`]="{ item, index }">
              <!-- 重量-->
              <div v-if="!item.isEditable">
                {{ item.weight }}
              </div>
              <div v-else>
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.weight"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.inputNumber53,
                    rules.inputNumberOverZero(item.weight, true),
                  ]"
                  @click="clickPop(item.weight)"
                  @change="changeWeight(item, index)"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'weightCheck' + index"
                />
              </div>
            </template>
            <!-- サイズ -->
            <template v-slot:[`item.freightSizeL`]="{ item, index }">
              <div v-if="!item.isEditable">
                <div>{{ item.freightSizeL }}</div>
              </div>
              <div v-else id="table-size">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeL"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeL)"
                  @change="changeSize(item, index, 'L')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeLCheck' + index"
                />
              </div>
            </template>
            <template v-slot:[`item.crossMark1`]>
              <div style="font-size: 30px">×</div>
            </template>
            <template v-slot:[`item.freightSizeW`]="{ item, index }">
              <div v-if="!item.isEditable" class="size-div">
                <div style="width: 100px">{{ item.freightSizeW }}</div>
              </div>
              <div v-else id="table-size">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeW"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeW)"
                  @change="changeSize(item, index, 'W')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeWCheck' + index"
                />
              </div>
            </template>
            <template v-slot:[`item.crossMark2`]>
              <div style="font-size: 30px">×</div>
            </template>
            <template v-slot:[`item.freightSizeH`]="{ item, index }">
              <div v-if="!item.isEditable" style="padding-left: 15px">
                {{ item.freightSizeH }}
              </div>
              <div v-else id="table-size">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.freightSizeH"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 180px"
                  :rules="[rules.isNumber, rules.inputNum52, rules.inputNumberOverZero]"
                  @click="clickPop(item.freightSizeH)"
                  @change="changeSize(item, index, 'H')"
                  :error-messages="item.listItemsErrorMsg"
                  :ref="'sizeHCheck' + index"
                />
              </div>
            </template>

            <!-- 小口数 -->
            <template v-slot:[`item.smallLotsQuantity`]="{ item, index }">
              <div v-if="!item.isEditable">
                {{ item.smallLotsQuantity }}
              </div>
              <div v-else id="table-smallQt">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.smallLotsQuantity"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck0,
                    rules.checkQuantity,
                  ]"
                  @click="clickPop(item.smallLotsQuantity)"
                  @change="changeSmallLotsQuantity(item, index)"
                  :error-messages="item.smallQtErrorMsg"
                  :ref="'smallLotsQtyCheck' + index"
                />
              </div>
            </template>

            <template v-slot:[`item.transportRequirementEdit`]="{ item, index }">
              <!-- 輸送要件編集ボタン-->
              <v-btn
                v-if="item.isEditable"
                small
                @click="openTransportRequirement(item, index)"
                text
                class="pen-icon"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.transportRequirement`]="{ item }">
              <!-- 輸送要件-->
              <v-container class="d-flex opt-hist-list-container">
                <template v-for="(transportReqItem, index) in transportRequirementList">
                  <!-- 選択した輸送要件を表示 -->
                  <div
                    class="flex-item"
                    v-if="item.transportRequirement.includes(transportReqItem.value)"
                    :key="index"
                    style="white-space: nowrap"
                  >
                    {{ transportReqItem.text }} /&nbsp;
                  </div>
                </template>
              </v-container>
              <div
                v-if="item.transportReqErrorMsg && item.isEditable"
                class="error-message"
                style="white-space: nowrap"
              >
                {{ item.transportReqErrorMsg }}
              </div>
            </template>

            <template v-slot:[`item.remarks`]="{ item }">
              <!-- 備考-->
              <div v-if="!item.isEditable">
                {{ item.remarks }}
              </div>
              <div v-else>
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.remarks"
                  maxlength="200"
                  clear-icon="mdi-close-circle"
                  clearable
                  style="width: 200px"
                  :error-messages="item.remarksErrorMsg"
                />
              </div>
            </template>

            <template v-slot:[`item.deleteData`]="{ item }">
              <!-- 削除-->
              <v-btn v-if="item.isEditable" small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(totalPage)"
              :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :trnListFlg="infoDialog.trnListFlg"
        :homePageFlag="infoDialog.homePageFlag"
        :trnAddFlg="infoDialog.trnAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 確認ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 行追加モーダル -->
      <v-dialog v-model="isOpenTransportAddDialog" :max-width="2000" persistent>
        <TransportListDialog
          :isOpenDetailDialog.sync="isOpenTransportAddDialog"
          :viewDataList="changeList"
          :listLength="totalRecord"
          v-on:child-event="parentMethod"
          :screenId="screenId"
          :packingNacCdList="packingNacCdList"
          :transportRequirementList="transportRequirementList"
        />
      </v-dialog>
      <!-- 輸送要件選択ダイアログ-->
      <v-dialog
        v-model="dialogTransportRequirementsBreakdown"
        :max-width="800"
        persistent=""
        no-click-animation
      >
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_transportRequirementSelect") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- 輸送要件選択テーブルデータ -->
              <v-data-table
                id="workInstructionsData"
                fixed-header
                :headers="transportRequirementsHeader"
                :items="transportRequirementList"
                disable-sort
                :hide-default-footer="true"
                :items-per-page="10"
              >
                <!-- 一覧チェックボックス -->
                <template v-slot:[`item.check`]="{ item }">
                  <input
                    type="checkbox"
                    v-model="item.check"
                    :value="listCheckbox"
                    style="transform: scale(2)"
                    :disabled="item.disabledFlg"
                  />
                </template>
                <!-- 輸送要件 -->
                <template v-slot:[`item.transportRequirement`]="{ item }">
                  <span>
                    <!-- 輸送要件を表示 -->
                    {{ item.text }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!-- 閉じるボタン領域 -->
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" @click="closeDialogTransportRequirements">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!-- 設定ボタン -->
                <v-btn class="other-btn" @click="setTransportRequirements">
                  {{ $t("btn.btn_config") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import TransportListDialog from "@/views/transport/TransportListDialog"; // 行追加モーダルダイアログ

export default {
  name: appConfig.MENU_ID.P_TOD_UPDATE,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CheckDialog,
    NavBar,
    TransportListDialog,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    suppliersSelectedText: "",
    supplierList: [],
    supplierHint: "",
    searchSuppliersSelected: "",
    // 更新日
    updateDatetime: "",
    // 発注日
    orderDate: "",
    orderDateCal: "",
    orderDateMenu: false,
    // 輸送担当営業所
    officeSelected: "",
    officeSelectedCd: "",
    officeList: [],
    productHintOffice: "",
    // 業務No
    bussinessNoSelected: "",
    bussinessNoSelectedText: "",
    bussinessNoList: [],
    // 集荷元
    pickupPointSelected: "",
    pickupPointCd: "",
    pickupPointSid: "",
    pickupPointList: [],
    productHintPickup: "",
    // 集荷区分
    prevPickupDivSelected: "",
    pickupDivSelected: "",
    pickupDivList: [],
    // 集荷予定日
    pickupScheduleDate: "",
    pickupScheduleDateCal: "",
    pickupScheduleDateMenu: false,
    // 集荷予定時間FROM
    pickupScheduleTimeFrom: "",
    // 集荷予定時間TO
    pickupScheduleTimeTo: "",
    // 配達先
    deliveryPointSelected: "",
    deliveryPointCd: "",
    deliveryPointSid: "",
    deliveryPointList: [],
    productHintDelivery: "",
    // 配達区分
    deliveryDivSelected: "",
    deliveryDivList: [],
    // 配達予定日
    deliveryScheduleDate: "",
    deliveryScheduleDateCal: "",
    deliveryScheduleDateMenu: false,
    // 配達予定時間FROM
    deliveryScheduleTimeFrom: "",
    // 配達予定時間TO
    deliveryScheduleTimeTo: "",
    // お客様問合せ番号
    inquiryNumber: "",
    // 対象/対象外
    isTarget: "0",
    targetDivList: [],
    // 非公開/公開
    isPrivate: "0",
    privateDivList: [],
    isDisabledPrivate: false,
    inputList: [],
    inputAddList: [],
    // 荷姿
    packingNacCdList: [],
    // 輸送要件
    transportRequirementList: [],
    transportRequirementAddList: [],
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    truckInfo: {
      suppliersSelected: "",
      officeSid: "",
      truckInfo: "",
    },
    // 輸送要件選択ダイアログ
    dialogTransportRequirementsBreakdown: false,
    transportRequirementsHeader: [
      { value: "check", align: "left", width: "10%" },
      {
        text: i18n.tc("label.lbl_transportRequirement"),
        value: "transportRequirement",
        align: "left",
        width: "90%",
      },
    ],
    // 輸送要件選択ボタン選択行インデックス
    clickRowIndex: 0,

    // 一覧選択チェックボックス
    listCheckbox: [],
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_packing"),
        value: "packing",
        width: "200px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_weight"),
        value: "weight",
        width: "210px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "freightSizeL",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        value: "crossMark1",
        width: "20px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_freightSize"),
        value: "freightSizeW",
        width: "80px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "crossMark2",
        width: "20px",
        align: "center",
      },
      {
        value: "freightSizeH",
        width: "80px",
        align: "center",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_smallLotsQuantity"),
        value: "smallLotsQuantity",
        width: "150px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        value: "transportRequirementEdit",
        width: "80px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_transportRequirementArrow"),
        value: "transportRequirement",
        width: "250px",
        align: "left",
      },
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        width: "230px",
        align: "center",
      },
      {
        // 削除
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "74px",
        align: "center",
      },
    ],
    // メニュー
    openMenu: null,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    defaultItem: {
      No: "",
      productName: "",
      packing: "",
      packingList: [],
      weight: "",
      productHint: "",
      freightSizeL: "",
      freightSizeW: "",
      freightSizeH: "",
      smallLotsQuantity: "",
      transportRequirement: [],
      transportReqMenu: false,
    },
    officeInput: "",
    pickupInput: "",
    deliveryInput: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    updateDialogMessage: false,
    backMessage: "",
    pickupPointAlertMessage: "",
    deliveryPointAlertMessage: "",
    deliveryDivAlertMessage: "",
    pickupScheduleTimeAlertMessage: "",
    deliveryScheduleDateAlertMessage: "",
    deliveryScheduleTimeAlertMessage: "",
    // 追加ボタン非活性フラグ
    isDisabledAddBtn: false,
    // 修正登録ボタン非活性フラグ
    isDisabledUpdateBtn: false,
    // 受注削除ボタン非活性フラグ
    isDisabledDelBtn: false,
    // 行追加ダイアログ表示
    isOpenTransportAddDialog: false,
    // 送り状番号
    searchInvNum: "",
    // 入力チェックエラーダイアログ用フラグ
    errorFlg: true,
    screenId: "P_TOD_003",
    changeList: [],
    totalRecord: 10,
    lastListFlg: false,
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    page: 1,
    itemsPerPage: 100,
    totalPage: 0,
    // 削除リスト
    deleteList: [],
    // 初期処理用フラグ
    initFlg: true,
    // 集荷元初期値フラグ
    isPickupInit: true,
    // 配達先初期値フラグ
    isdeliveryInit: true,
    // バリデーションルール
    rules: {
      inputNumber92: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");
            if (numL[0].length > 9 || numL[1].length > 2)
              return i18n.tc("check.chk_inputNumber_9-2");
          } else {
            if (ar.length > 9) {
              return i18n.tc("check.chk_inputNumber_9-2");
            }
          }
        }
        return true;
      },
      checkQuantity: function (value) {
        let isValid;
        if (value != null && value != undefined && value != "") {
          value = String(value).replaceAll(",", "");
          const regex = /^([1-9])(\d{0,4})$/;
          if (regex.test(Number(value))) {
            isValid = true;
          } else {
            isValid = false;
          }
          if (!isValid) {
            return i18n.tc("check.chk_inputNumber_5");
          }
        }
        return true;
      },
      inputNumberOverZero: (value, isWeight) => {
        if (value != null && value != undefined && value != "") {
          let numberVal = value;
          if (numberVal == 0) {
            if (isWeight) {
              return i18n.tc("check.chk_inputMoreOneDotZero");
            } else {
              return i18n.tc("check.chk_inputMoreOneDot");
            }
          }
        }
        return true;
      },
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // 遷移元画面からのパラメータ取得
    // 検索条件
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
    }
    // 送り状番号
    if (this.$route.params.invNumSid) {
      this.searchInvNum = this.$route.params.invNumSid;
    }
  },

  methods: {
    /*
     * 初期起動：初期値設定
     */
    init() {
      // ローディング開始
      this.loadingCounter = 1;

      // コードマスタを取得
      this.getMstCode();

      // 受注情報を取得
      this.getApiList();

      // キャッシュ用明細取得
      this.getDetailForCache(); // 全ての受注明細

      // 受注明細取得
      this.getDetail(); // 画面上に表示する分の受注明細
    },

    /**
     * コードマスタを取得
     */
    getMstCode() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // ------- ↓↓仮置きの値↓↓-----------
      // const pickingList = [
      //   {
      //     text: "自拠点出荷",
      //     value: "01",
      //   },
      //   {
      //     text: "荷主出荷（持込）",
      //     value: "02",
      //   },
      //   {
      //     text: "荷主出荷（集荷）",
      //     value: "03",
      //   },
      // ];
      // const deliveryList = [
      //   {
      //     text: "配達要",
      //     value: "01",
      //   },
      //   {
      //     text: "営業所止め",
      //     value: "02",
      //   },
      // ];
      // const packingCodeList = [
      //   {
      //     text: "洋樽",
      //     value: "BA",
      //   },
      //   {
      //     text: "束",
      //     value: "BE",
      //   },
      //   {
      //     text: "袋",
      //     value: "BG",
      //   },
      // ];
      // const targetList = [
      //   {
      //     text: "対象",
      //     value: "0",
      //   },
      //   {
      //     text: "対象外",
      //     value: "1",
      //   },
      // ];
      // const privateList = [
      //   {
      //     text: "非公開",
      //     value: "0",
      //   },
      //   {
      //     text: "公開",
      //     value: "1",
      //   },
      // ];
      // const transportList = [
      //   {
      //     text: "われもの注意",
      //     value: "01",
      //   },
      //   {
      //     text: "取扱注意",
      //     value: "02",
      //   },
      //   {
      //     text: "水濡注意",
      //     value: "03",
      //   },
      // ];
      // 集荷方法区分
      const pickingList = getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.PICKUP_DIV });
      // 配達方法区分
      const deliveryList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.DELIVERY_DIV,
      });
      // 荷姿(NACCSコード)
      const packingCodeList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.NACCS_CODE,
      });
      // 配車計画対象フラグ
      const targetList = getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.IS_TARGET_FLG });
      // 公開フラグ
      const privateList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.IS_PRIVATE_FLG,
      });
      // 輸送要件
      const transportList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.TRANSPORT_REQUIREMENT_DIV,
      });
      Promise.all([
        pickingList,
        deliveryList,
        packingCodeList,
        targetList,
        privateList,
        transportList,
      ])
        .then((result) => {
          // 画面の初期値を設定
          this.pickupDivList = result[0];
          this.deliveryDivList = result[1];
          this.packingNacCdList = result[2];
          this.targetDivList = result[3];
          this.privateDivList = result[4];
          this.transportRequirementList = result[5];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        });
      // .finally(() => {
      //   // ローディング画面表示OFF
      //   this.loadingCounter = 0;
      //   for (var j = 0; j < 10; j++) {
      //     this.defaultItem.No = j + 1;
      //     this.defaultItem.packingList = this.packingNacCdList;
      //     this.inputList.push(Object.assign({}, this.defaultItem));
      //   }
      // });
    },

    /**
     * 受注情報を取得
     */
    getApiList() {
      // GetAPIリクエスト用のConfigを生成
      const config = this.$httpClient.createGetApiRequestConfig();

      // 送り状SIDを設定
      config.params.invNumSid = this.searchInvNum;

      // Configをログ出力
      // console.debug("getApiList() Config", config);

      let inputDataList = [];
      // 非同期処理を開始
      return new Promise((resolve, reject) => {
        // ↓↓----------仮置きの値(ローカル用)---------------
        // const jsonData = {
        //   resCom: {
        //     resComStatus: "Success",
        //     resComCode: "000",
        //     resComCount: 1,
        //   },
        //   resIdv: {
        //     orders: [
        //       {
        //         supplierCd: "4400710",
        //         supplierName: "ボルボ・カー・ジャパン㈱ 埼玉サービスセンター ",
        //         officeSid: "2400000001",
        //         businessNo: "440710",
        //         pickupWayDiv: "03",
        //         pickupBaseSid: "2400000002",
        //         pickupBaseName: "VCJ埼玉SC",
        //         pickupScheduleDate: "2024/08/20 15:00:00.000",
        //         pickupScheduleTime: "10:15 - 12:45",
        //         pickupScheduleTimeFrom: "2024/08/21 01:15:00.000",
        //         pickupScheduleTimeTo: "2024/08/21 03:45:00.000",
        //         deliveryWayDiv: "01",
        //         deliveryBaseSid: "2400000003",
        //         deliveryBaseName: "VC東名横浜",
        //         deliveryScheduleDate: "2024/08/21 15:00:00.000",
        //         deliveryScheduleTime: "20:30 - 22:00",
        //         deliveryScheduleTimeFrom: "2024/08/22 11:30:00.000",
        //         deliveryScheduleTimeTo: "2024/08/22 13:00:00.000",
        //         pickupStatusName: "未配車",
        //         deliveryStatusName: "未配車",
        //         vehiclePlanFlg: "1",
        //         vehiclePlanName: "対象",
        //         privateFlg: "1",
        //         privateName: "公開",
        //         orderDate: "2024/08/19 15:00:00.000",
        //         totalWeight: "500.000",
        //         smallLotsQuantity: "5",
        //         invNumSid: "24000000000000000276",
        //         inquiryNumber: "1234567890",
        //         invoicePrintDate: null,
        //         taglabelPrintDate: null,
        //       },
        //     ],
        //   },
        // };
        // // 処理結果コードが"000"（Success・Retry）の場合の処理
        // if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
        //   // リストを生成
        //   const list = [];

        //   // JSONオブジェクトに対する繰り返し処理
        //   jsonData.resIdv.orders.forEach((row) => {
        //     // リストにハッシュを追加
        //     list.push({
        //       // 取引先
        //       supplier: row.supplierCd,
        //       // 輸送担当営業所SID
        //       office: row.officeSid,
        //       // 業務No
        //       bussinessNo: row.businessNo,
        //       // 集荷方法区分
        //       pickupDiv: row.pickupWayDiv,
        //       // 集荷元
        //       pickupPoint: row.pickupBaseSid,
        //       // 集荷予定日（画面表示用=JST:秒数なし）
        //       pickupScheduleDate: dateTimeHelper
        //         .convertUTC2JST(row.pickupScheduleDate)
        //         .substr(0, 10),
        //       // 集荷予定時間From
        //       pickupScheduleTimeFrom: row.pickupScheduleTimeFrom,
        //       // 集荷予定時間To
        //       pickupScheduleTimeTo: row.pickupScheduleTimeTo,
        //       // 配達先
        //       deliveryPoint: row.deliveryBaseSid,
        //       // 配達方法区分
        //       deliveryDiv: row.deliveryWayDiv,
        //       // 配達予定日（画面表示用=JST:秒数なし）
        //       deliveryScheduleDate: dateTimeHelper
        //         .convertUTC2JST(row.deliveryScheduleDate)
        //         .substr(0, 10),
        //       // 配達予定時間From
        //       deliveryScheduleTimeFrom: row.deliveryScheduleTimeFrom,
        //       // 配達予定時間To
        //       deliveryScheduleTimeTo: row.deliveryScheduleTimeTo,
        //       // 対象/対象外
        //       vehiclePlanFlg: row.vehiclePlanFlg,
        //       // 公開/非公開
        //       privateFlg: row.privateFlg,
        //       // 発注日（画面表示用=JST:秒数なし）
        //       orderDate: dateTimeHelper.convertUTC2JST(row.orderDate).substr(0, 10),
        //       // お客様問合せ番号
        //       inquiryNum: row.inquiryNumber,
        //     });
        //   });

        //   // リストを検索に使う予定一覧に設定
        //   inputDataList = list;
        //   // タスク終了
        //   resolve();
        // } else {
        //   reject();
        // }
        // ↑↑----------仮置きの値(ローカル用)---------------

        this.$httpClient
          // 受注を取得
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_SEARCH, config)
          // 成功時の処理
          .then((response) => {
            // レスポンスをログ出力
            // console.debug("getApiList() Response", response);

            // レスポンスからJSONオブジェクトを取得
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // リストを生成
              const list = [];

              // JSONオブジェクトに対する繰り返し処理
              jsonData.resIdv.orders.forEach((row) => {
                // リストにハッシュを追加
                list.push({
                  // 取引先
                  supplier: row.supplierCd,
                  updateDateTime: row.updateDateTime,
                  // 輸送担当営業所SID
                  office: row.officeSid,
                  // 業務No
                  bussinessNo: row.businessNo,
                  // 集荷方法区分
                  pickupDiv: row.pickupWayDiv,
                  // 集荷元
                  pickupPoint: row.pickupBaseSid,
                  // 集荷予定日（画面表示用=JST:秒数なし）
                  pickupScheduleDate: dateTimeHelper
                    .convertUTC2JST(row.pickupScheduleDate)
                    .substr(0, 10),
                  // 集荷予定時間From
                  pickupScheduleTimeFrom: row.pickupScheduleTimeFrom,
                  // 集荷予定時間To
                  pickupScheduleTimeTo: row.pickupScheduleTimeTo,
                  // 配達先
                  deliveryPoint: row.deliveryBaseSid,
                  // 配達方法区分
                  deliveryDiv: row.deliveryWayDiv,
                  // 配達予定日（画面表示用=JST:秒数なし）
                  deliveryScheduleDate: dateTimeHelper
                    .convertUTC2JST(row.deliveryScheduleDate)
                    .substr(0, 10),
                  // 配達予定時間From
                  deliveryScheduleTimeFrom: row.deliveryScheduleTimeFrom,
                  // 配達予定時間To
                  deliveryScheduleTimeTo: row.deliveryScheduleTimeTo,
                  // 対象/対象外
                  vehiclePlanFlg: row.vehiclePlanFlg,
                  // 公開/非公開
                  privateFlg: row.privateFlg,
                  // 発注日（画面表示用=JST:秒数なし）
                  orderDate: dateTimeHelper.convertUTC2JST(row.orderDate).substr(0, 10),
                  // お客様問合せ番号
                  inquiryNum: row.inquiryNumber,
                });
              });

              // リストを検索に使う予定一覧に設定
              inputDataList = list;
              // タスク終了
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              // タスクを終了（失敗）。
              reject();
            }
          })
          // 失敗時の処理
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            // タスクを終了（失敗）。
            reject();
          });
        // 成功時の処理
      }).then(() => {
        // 検索に使う予定一覧の要素数が0より大きい場合の処理
        if (inputDataList.length > 0) {
          // 取引先
          this.suppliersSelected = inputDataList[0].supplier;
          // 更新日時
          this.updateDatetime = inputDataList[0].updateDateTime;
          // 発注日
          this.orderDateCal = dateTimeHelper.dateCalc(inputDataList[0].orderDate);
          // 輸送担当営業所
          this.officeSelected = inputDataList[0].office;
          // 業務No
          this.bussinessNoSelected = inputDataList[0].bussinessNo;
          // 対象/対象外
          this.isTarget = inputDataList[0].vehiclePlanFlg;
          if (this.isTarget === "1") {
            this.isDisabledPrivate = true;
          }
          // 公開/非公開
          this.isPrivate = inputDataList[0].privateFlg;
          // 集荷元
          this.pickupPointSelected = inputDataList[0].pickupPoint;
          // 集荷方法区分
          this.pickupDivSelected = inputDataList[0].pickupDiv;
          // 集荷予定日
          this.pickupScheduleDateCal = dateTimeHelper.dateCalc(inputDataList[0].pickupScheduleDate);
          // 集荷予定時間From
          if (!inputDataList[0].pickupScheduleTimeFrom) {
            this.pickupScheduleTimeFrom = "";
          } else {
            this.pickupScheduleTimeFrom = dateTimeHelper
              .convertUTC2JST(inputDataList[0].pickupScheduleTimeFrom)
              .substring(11, 16);
          }
          // 集荷予定時間To
          if (!inputDataList[0].pickupScheduleTimeTo) {
            this.pickupScheduleTimeTo = "";
          } else {
            this.pickupScheduleTimeTo = dateTimeHelper
              .convertUTC2JST(inputDataList[0].pickupScheduleTimeTo)
              .substring(11, 16);
          }
          // 配達先
          this.deliveryPointSelected = inputDataList[0].deliveryPoint;
          // 配達方法区分
          this.deliveryDivSelected = inputDataList[0].deliveryDiv;
          // 配達予定日
          this.deliveryScheduleDateCal = dateTimeHelper.dateCalc(
            inputDataList[0].deliveryScheduleDate
          );
          // 配達予定時間From
          if (!inputDataList[0].deliveryScheduleTimeFrom) {
            this.deliveryScheduleTimeFrom = "";
          } else {
            this.deliveryScheduleTimeFrom = dateTimeHelper
              .convertUTC2JST(inputDataList[0].deliveryScheduleTimeFrom)
              .substring(11, 16);
          }
          // 配達予定時間To
          if (!inputDataList[0].deliveryScheduleTimeTo) {
            this.deliveryScheduleTimeTo = "";
          } else {
            this.deliveryScheduleTimeTo = dateTimeHelper
              .convertUTC2JST(inputDataList[0].deliveryScheduleTimeTo)
              .substring(11, 16);
          }
          // お客様問合せ番号
          this.inquiryNumber = inputDataList[0].inquiryNum;

          // 取引先リスト取得
          this.getCustomInfo(this.suppliersSelected);
          // 輸送担当営業所リスト取得
          this.getOfficeList(this.officeSelected, "sid");
          // 集荷元選択リスト取得
          this.getPickupSelected(this.pickupPointSelected, "sid");
          // 配達先選択リスト取得
          this.getDeliverySelected(this.deliveryPointSelected, "sid");
        } else {
          // 処理なし
        }
      });
    },
    /**
     * 受注明細（画面表示用）を取得
     */
    getDetail() {
      this.inputList = [];

      // GetAPIリクエスト用のConfigを生成
      const config = this.$httpClient.createGetApiRequestConfig();

      // 送り状SIDを設定
      config.params.invNumSid = this.searchInvNum;

      // ソート順を設定
      //config.params.reqComSortItem = "out_list_sub_sid asc";
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // ↓↓-------API完成次第、修正=-------↓↓
      // ↓↓------ローカル用=-------↓↓
      // const list = [];
      // // 修正可能の行
      // for (let i = 0; i < 5; i++) {
      //   list.push({
      //     No: list.length + 1,
      //     packingSid: "0000" + list.length,
      //     // 品名
      //     productName: "品名テスト" + list.length,
      //     // 荷姿
      //     packing: "NE",
      //     // 重量
      //     weight: String(commonFunction.getDelimiterFew(Number("3000", 9, 3))),
      //     // サイズ
      //     freightSizeL: String(commonFunction.getDelimiterFew(Number("4000", 9, 2))),
      //     freightSizeW: String(commonFunction.getDelimiterFew(Number("5000", 9, 2))),
      //     freightSizeH: String(commonFunction.getDelimiterFew(Number("6000", 9, 2))),
      //     // 小口数
      //     smallLotsQuantity: String(commonFunction.getDelimiter(Number("7000", 5))),
      //     // 輸送要件の文字列
      //     transportRequirement: ["01", "03"],
      //     remarks: "備考テスト",
      //     pickupStatusDiv: "00",
      //     deliveryStatusDiv: "00",
      //   });
      // }
      // 修正不可の行
      // for (let i = 5; i < 10; i++) {
      //   list.push({
      //     No: list.length + 1,
      //     packingSid: "0000" + list.length,
      //     // 品名
      //     productName: "品名テスト" + list.length,
      //     // 荷姿
      //     packing: "BA",
      //     // 重量
      //     weight: String(commonFunction.getDelimiterFew(Number("3000", 9, 3))),
      //     // サイズ
      //     freightSizeL: String(commonFunction.getDelimiterFew(Number("4000", 9, 2))),
      //     freightSizeW: String(commonFunction.getDelimiterFew(Number("5000", 9, 2))),
      //     freightSizeH: String(commonFunction.getDelimiterFew(Number("6000", 9, 2))),
      //     // 小口数
      //     smallLotsQuantity: String(commonFunction.getDelimiter(Number("7000", 5))),
      //     // 輸送要件の文字列
      //     transportRequirement: ["01", "03"],
      //     remarks: "備考テスト",
      //     pickupStatusDiv: "01",
      //     deliveryStatusDiv: "00",
      //   });
      // }
      // this.inputList = list;

      // // 活性非活性判定
      // for (const item of this.inputList) {
      //   // 集荷状態区分：00かつ配達状態区分：00 の場合、修正可能
      //   if (item.pickupStatusDiv === "00" && item.deliveryStatusDiv === "00") {
      //     item.isEditable = true;
      //   }
      // }
      // ↑↑-------------------------

      // Configをログ出力
      // console.debug("getDetail() Config", config);

      // ↓↓-------API完成次第、修正=-------↓↓
      // 非同期処理を開始
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 受注明細を取得
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_DETAIL_SEARCH, config)
          // 成功時の処理
          .then((response) => {
            // レスポンスをログ出力
            //console.log("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから受注明細を取得
            const apiData = jsonData.resIdv.orderDetails;

            if (this.initFlg) {
              this.totalPage = jsonData.resCom.resComPagination.totalPage;
              this.totalRecord = jsonData.resCom.resComPagination.totalRecord;
              this.totalCount = jsonData.resIdv.actualCnt;
            }

            // 処理結果コードが"000"（Success・Retry）の場合
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 受注明細データの設定
              for (var j = 0; j < apiData.length; j++) {
                let apiDataItem = apiData[j];
                let inputListItem = {};

                // No
                inputListItem.No = j + 1 + this.itemsPerPage * (this.page - 1);

                inputListItem.packingSids = apiDataItem.packingSids;
                // 品名
                inputListItem.productName = apiDataItem.itemName;
                // 荷姿
                inputListItem.packing = apiDataItem.packingNacCd;
                // 重量
                inputListItem.weight = String(
                  commonFunction.getDelimiterFew(Number(apiDataItem.totalWeight, 9, 3))
                );
                // サイズ
                inputListItem.freightSizeL = this.chkSizeValue(apiDataItem.freightSizeL);
                inputListItem.freightSizeW = this.chkSizeValue(apiDataItem.freightSizeW);
                inputListItem.freightSizeH = this.chkSizeValue(apiDataItem.freightSizeH);
                // 小口数
                inputListItem.smallLotsQuantity = String(
                  commonFunction.getDelimiter(Number(apiDataItem.smallLotsQuantity, 5))
                );
                // 輸送要件
                inputListItem.transportRequirement = apiDataItem.transportRequirements.map(
                  (p) => p.transportRequirement
                );
                // 備考
                inputListItem.remarks = apiDataItem.remarks;
                inputListItem.pickupStatusDiv = apiDataItem.pickupStatusDiv;
                inputListItem.deliveryStatusDiv = apiDataItem.deliveryStatusDiv;
                // 集荷状態区分：00かつ配達状態区分：00 の場合、修正可能
                if (
                  inputListItem.pickupStatusDiv === "00" &&
                  inputListItem.deliveryStatusDiv === "00"
                ) {
                  inputListItem.isEditable = true;
                }
                this.inputList.push(inputListItem);
              }
              // タスク終了
              resolve(response);
            }
          })
          // 失敗時の処理
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = false;
            this.infoDialog.firstPageFlag = true;

            // タスク終了
            reject();
          });
      });
      // ↑↑---------------------------------------------------------↑↑
    },

    /**
     * 受注明細（全件数）を取得
     */
    getDetailForCache() {
      // GetAPIリクエスト用のConfigを生成
      const config = this.$httpClient.createGetApiRequestConfig();
      // 出荷伝票SIDを設定
      config.params.invNumSid = this.searchInvNum;
      // 削除フラグを設定
      config.params.deleteFlg = "0";
      // 基準日を設定
      //config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);
      // ソート順を設定
      //config.params.reqComSortItem = "out_list_sub_sid asc";

      // ↓↓-------API完成次第、修正=-------↓↓
      // ↓↓------ローカル用=-------↓↓
      // const list = [];
      // // 修正可能の行
      // for (let i = 0; i < 5; i++) {
      //   list.push({
      //     No: list.length + 1,
      //     packingSid: "0000" + list.length,
      //     // 品名
      //     productName: "品名テスト" + list.length,
      //     // 荷姿
      //     packing: "BA",
      //     // 重量
      //     weight: String(commonFunction.getDelimiterFew(Number("3000", 9, 3))),
      //     // サイズ
      //     freightSizeL: String(commonFunction.getDelimiterFew(Number("4000", 9, 2))),
      //     freightSizeW: String(commonFunction.getDelimiterFew(Number("5000", 9, 2))),
      //     freightSizeH: String(commonFunction.getDelimiterFew(Number("6000", 9, 2))),
      //     // 小口数
      //     smallLotsQuantity: String(commonFunction.getDelimiter(Number("7000", 5))),
      //     // 輸送要件の文字列
      //     transportRequirement: ["01", "03"],
      //     remarks: "備考テスト",
      //     pickupStatusDiv: "00",
      //     deliveryStatusDiv: "00",
      //   });
      // }
      // // 修正不可の行
      // // for (let i = 5; i < 10; i++) {
      // //   list.push({
      // //     No: list.length + 1,
      // //     packingSid: "0000" + list.length,
      // //     // 品名
      // //     productName: "品名テスト" + list.length,
      // //     // 荷姿
      // //     packing: "BA",
      // //     // 重量
      // //     weight: String(commonFunction.getDelimiterFew(Number("3000", 9, 3))),
      // //     // サイズ
      // //     freightSizeL: String(commonFunction.getDelimiterFew(Number("4000", 9, 2))),
      // //     freightSizeW: String(commonFunction.getDelimiterFew(Number("5000", 9, 2))),
      // //     freightSizeH: String(commonFunction.getDelimiterFew(Number("6000", 9, 2))),
      // //     // 小口数
      // //     smallLotsQuantity: String(commonFunction.getDelimiter(Number("7000", 5))),
      // //     // 輸送要件の文字列
      // //     transportRequirement: ["01", "03"],
      // //     remarks: "備考テスト",
      // //     pickupStatusDiv: "01",
      // //     deliveryStatusDiv: "00",
      // //   });
      // // }
      // this.changeList = list;
      // this.totalRecord = this.changeList.length;

      // // ボタン活性非活性判定
      // let editableOrderNum = 0; // 修正可能な明細の件数
      // for (const item of this.changeList) {
      //   // 集荷状態区分：00かつ配達状態区分：00 の場合、修正可能
      //   if (item.pickupStatusDiv === "00" && item.deliveryStatusDiv === "00") {
      //     item.isEditable = true;
      //     editableOrderNum++;
      //   }
      // }

      // // 修正可能な明細が一件もない
      // if (editableOrderNum === 0) {
      //   // 全てのボタン非活性
      //   this.isDisabledUpdateBtn = true;
      //   this.isDisabledDelBtn = true;
      //   this.isDisabledAddBtn = true;

      //   // 全ての受注明細が修正可能
      // } else if (editableOrderNum === this.changeList.length) {
      //   // 全てのボタン活性
      //   this.isDisabledUpdateBtn = false;
      //   this.isDisabledDelBtn = false;
      //   this.isDisabledAddBtn = false;

      //   // それ以外
      // } else {
      //   // 受注削除ボタンのみ非活性
      //   this.isDisabledUpdateBtn = false;
      //   this.isDisabledDelBtn = true;
      //   this.isDisabledAddBtn = false;
      // }

      // ↑↑------ローカル用=-------↑↑

      // Configをログ出力
      // console.debug("getDetail() Config", config);

      // 非同期処理を開始
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 受注明細を取得
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_DETAIL_SEARCH, config)
          // 成功時の処理
          .then((response) => {
            // レスポンスをログ出力
            // console.debug("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから受注明細を取得
            const apiData = jsonData.resIdv.orderDetails;
            // 処理結果コードが"000"（Success・Retry）の場合
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              /// 受注明細データの設定
              for (var j = 0; j < apiData.length; j++) {
                let apiDataItem = apiData[j];
                let inputListItem = {};
                // No
                inputListItem.No = j + 1 + this.itemsPerPage * (this.page - 1);
                inputListItem.packingSids = apiDataItem.packingSids;
                // 品名
                inputListItem.productName = apiDataItem.itemName;
                // 荷姿
                inputListItem.packing = apiDataItem.packingNacCd;
                // 重量
                inputListItem.weight = String(
                  commonFunction.getDelimiterFew(Number(apiDataItem.totalWeight, 9, 3))
                );
                // サイズ
                inputListItem.freightSizeL = this.chkSizeValue(apiDataItem.freightSizeL);
                inputListItem.freightSizeW = this.chkSizeValue(apiDataItem.freightSizeW);
                inputListItem.freightSizeH = this.chkSizeValue(apiDataItem.freightSizeH);
                // 小口数
                inputListItem.smallLotsQuantity = String(
                  commonFunction.getDelimiter(Number(apiDataItem.smallLotsQuantity, 5))
                );
                // 輸送要件
                inputListItem.transportRequirement = apiDataItem.transportRequirements.map(
                  (p) => p.transportRequirement
                );
                // 備考
                inputListItem.remarks = apiDataItem.remarks;
                // 集荷状態区分
                inputListItem.pickupStatusDiv = apiDataItem.pickupStatusDiv;
                // 配達状態区分
                inputListItem.deliveryStatusDiv = apiDataItem.deliveryStatusDiv;

                this.changeList.push(inputListItem);
              }
              // タスクを終了（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              // タスクを終了（失敗）。
              reject();
            }
          })
          // 失敗時の処理
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            // タスクを終了（失敗）。
            reject();
          })
          // 成功・失敗に関わらず実行される処理
          .finally(() => {
            // ローディング画面の表示をOFFに
            this.loadingCounter = 0;
            this.loadingflg = true;
          });
        // 成功時の処理
      }).then(() => {
        // ボタン活性非活性判定
        let editableOrderNum = 0; // 修正可能な明細の件数
        for (const item of this.changeList) {
          // 集荷状態区分：00かつ配達状態区分：00 の場合、修正可能
          if (item.pickupStatusDiv === "00" && item.deliveryStatusDiv === "00") {
            item.isEditable = true;
            editableOrderNum++;
          }
        }

        // 修正可能な明細が一件もない
        if (editableOrderNum === 0) {
          // 全てのボタン活性
          this.isDisabledUpdateBtn = true;
          this.isDisabledDelBtn = true;
          this.isDisabledAddBtn = true;

          // 全ての受注明細が修正可能
        } else if (editableOrderNum === this.changeList.length) {
          // 全てのボタン非活性
          this.isDisabledUpdateBtn = false;
          this.isDisabledDelBtn = false;
          this.isDisabledAddBtn = false;

          // それ以外
        } else {
          // 受注削除ボタンのみ非活性
          this.isDisabledUpdateBtn = false;
          this.isDisabledDelBtn = true;
          this.isDisabledAddBtn = false;
        }
      });
      // ↑↑---------------------------------------------------------↑↑
    },

    changeIsTarget(newVal) {
      if (newVal == "1") {
        this.isDisabledPrivate = true;
      } else {
        this.isDisabledPrivate = false;
      }
    },
    /**
     * 受注修正削除画面：入力カンマ処理 重さ
     */
    changeWeight(val, index) {
      this.inputList[index].weight = commonFunction.getDelimiterFew(val.weight, 9, 3);
    },
    /**
     * 受注修正削除画面：入力カンマ処理 サイズ
     */
    changeSize(val, index, param) {
      switch (param) {
        case "L":
          this.inputList[index].freightSizeL = commonFunction.getDelimiterFew(
            val.freightSizeL,
            9,
            2
          );
          break;
        case "W":
          this.inputList[index].freightSizeW = commonFunction.getDelimiterFew(
            val.freightSizeW,
            9,
            2
          );
          break;
        case "H":
          this.inputList[index].freightSizeH = commonFunction.getDelimiterFew(
            val.freightSizeH,
            9,
            2
          );
          break;
      }
    },
    /**
     * 受注修正削除画面：入力カンマ処理 小口数
     */
    changeSmallLotsQuantity(val, index) {
      this.inputList[index].smallLotsQuantity = commonFunction.getDelimiter(
        val.smallLotsQuantity,
        5
      );
    },
    // カンマ削除処理
    clickPop(val) {
      let myName = event.target;
      let pop = val ?? "";
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 受注修正削除画面：日付を－１日
     */
    prevDate(param) {
      switch (param) {
        case "order":
          this.orderDateCal = commonFunction.addDate(this.orderDateCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateCal = commonFunction.addDate(this.pickupScheduleDateCal, "");
          break;
        case "delivery":
          this.deliveryScheduleDateCal = commonFunction.addDate(this.deliveryScheduleDateCal, "");
          break;
      }
    },

    /**
     * 受注修正削除画面：日付を＋１日
     */
    nextDate(param) {
      switch (param) {
        case "order":
          this.orderDateCal = commonFunction.addDate(this.orderDateCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateCal = commonFunction.addDate(this.pickupScheduleDateCal, "add");
          break;
        case "delivery":
          this.deliveryScheduleDateCal = commonFunction.addDate(
            this.deliveryScheduleDateCal,
            "add"
          );
          break;
      }
    },

    /**
     * 受注修正削除画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 受注修正削除画面：行削除処理
     */
    deleteItem(item) {
      // 初期処理の場合、inputListの内容をキャッシュ用リストに移行する。
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }

      // 削除ボタンを押した行のインデックス
      this.editedIndex = this.inputList.indexOf(item);
      // changeListの中のインデックス
      var changeIndex = this.inputList[this.editedIndex].No - 1;

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }
      this.changeList.splice(changeIndex, 1);
      var i = 1;
      // 番号付け

      this.changeList.forEach((data) => {
        data.No = i;
        i += 1;
      }); // inputList に修正した内容を移行

      this.useCache();

      if (this.changeList.length == 0) {
        this.totalCount = 0;
        this.totalPage = 0;
        this.totalRecord = 0;
      } else {
        this.totalPage = Math.ceil(this.changeList.length / this.itemsPerPage);
        this.totalRecord = this.totalRecord - 1;
      }

      this.$refs["weightCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeLCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeWCheck" + this.editedIndex].resetValidation();
      this.$refs["sizeHCheck" + this.editedIndex].resetValidation();
      this.$refs["smallLotsQtyCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 受注修正削除画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * 受注修正削除画面：輸送担当営業所コードリストボックス取得処理
     */
    setSrhOffice() {
      return commonFunction.getListSetName(this.officeList, this.officeSelected);
    },
    /**
     * 受注修正削除画面：集荷元コードリストボックス取得処理
     */
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPointSelected);
    },
    /**
     * 受注修正削除画面：配達先コードリストボックス取得処理
     */
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPointSelected);
    },

    /**
     * 受注修正削除画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDisabledAddBtn = true;
      // 追加ダイアログを開く
      this.isOpenTransportAddDialog = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDisabledAddBtn = false;
    },

    /**
     * 受注修正削除画面：日付入力処理
     */
    changeDate(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateCal = formatedDt;
          break;
      }
    },

    /**
     * 受注修正削除画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 受注修正削除画面：登録ボタン押下
     */
    trnAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      this.useCache();
      for (let i = 0; i < this.changeList.length; i++) {
        this.inputList[i].listItemsErrorMsg = i18n.tc("");
        this.inputList[i].smallQtErrorMsg = i18n.tc("");
        this.inputList[i].transportReqErrorMsg = i18n.tc("");
        this.inputList[i].remarksErrorMsg = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      this.addFlg = true;
      let cnt = 0;
      let countCheckFlg = true;
      this.pickupPointAlertMessage = "";
      this.deliveryPointAlertMessage = "";
      this.deliveryDivAlertMessage = "";
      this.pickupScheduleTimeAlertMessage = "";
      this.deliveryScheduleDateAlertMessage = "";
      this.deliveryScheduleTimeAlertMessage = "";
      // 明細部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.changeList.length; i++) {
          if (this.changeList[i].productName) {
            // 明細部の品名が入力されている場合はリストに追加
            checkList.push(this.changeList[i].productName);
          } else {
            // 上記以外の場合は処理なし。
          }
        }
        if (
          this.pickupPointCd == this.deliveryPointCd &&
          this.pickupPointSid == this.deliveryPointSid
        ) {
          this.deliveryPointAlertMessage = i18n.tc("check.chk_pickupDeliveryPointVali");
          return;
        }
        // 配達方法区分が自拠点出荷または荷主出荷（持込）の場合
        if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
          if (this.deliveryDivSelected == "02") {
            // 配達方法区分が営業所止めの場合はエラー
            this.deliveryDivAlertMessage = i18n.tc("check.chk_pickupDeliveryDivVali");
            return;
          }
        }
        // 集荷方法区分が自拠点出荷または荷主出荷（持込）の場合
        if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
          if (this.officeSelected != this.pickupPointSelected) {
            // 輸送担当営業所と集荷元が同じでない場合はエラー
            this.pickupPointAlertMessage = i18n.tc("check.chk_pickupDeliveryDivPointVali");
            return;
          }
        }
        // 配達方法区分が配達要の場合
        if (this.deliveryDivSelected == "01") {
          if (this.officeSelected == this.deliveryPointSelected) {
            // 輸送担当拠点と配達先が同じ場合はエラー
            this.deliveryPointAlertMessage = i18n.tc("check.chk_officeDeliveryDivPointVali");
            return;
          }
        }
        // 配達方法区分が営業所止めの場合
        if (this.deliveryDivSelected == "02") {
          if (this.officeSelected != this.deliveryPointSelected) {
            // 輸送担当営業所と配達先が同じでない場合はエラー
            this.deliveryPointAlertMessage = i18n.tc("check.chk_deliveryDivPointVali");
            return;
          }
        }
        if (
          // 集荷予定日、配達予定日の日付、相関チェック
          new Date(this.pickupScheduleDate).getTime() >
          new Date(this.deliveryScheduleDate).getTime()
        ) {
          this.deliveryScheduleDateAlertMessage = i18n.tc("check.chk_inputDeliveryScheduleVali");
          return;
        } else {
          // 処理なし
        }
        const wd = "2000/01/01";
        const startDate = new Date(`${wd} ${this.pickupScheduleTimeFrom}`);
        const endDate = new Date(`${wd} ${this.pickupScheduleTimeTo}`);
        if (
          // 集荷予定時間のFromTo、入力チェック
          this.pickupScheduleTimeFrom &&
          this.pickupScheduleTimeTo &&
          startDate > endDate
        ) {
          this.pickupScheduleTimeAlertMessage = i18n.tc("check.chk_inputFromDate");
          return;
        } else {
          // 処理なし
        }
        const startDate2 = new Date(`${wd} ${this.deliveryScheduleTimeFrom}`);
        const endDate2 = new Date(`${wd} ${this.deliveryScheduleTimeTo}`);
        if (
          // 配達予定時間のFromTo、入力チェック
          this.deliveryScheduleTimeFrom &&
          this.deliveryScheduleTimeTo &&
          startDate2 > endDate2
        ) {
          this.deliveryScheduleTimeAlertMessage = i18n.tc("check.chk_inputFromDate");
          return;
        } else {
          // 処理なし
        }

        var errNo = false;
        var duplicateFlg = false;
        for (let i = 0; i < this.changeList.length; i++) {
          let count = 0;
          // 品名の入力有無チェック
          if (this.changeList[i].productName != null && this.changeList[i].productName != "") {
            count++;
          }
          // 荷姿の入力有無チェック
          if (this.changeList[i].lbl_packing != null && this.changeList[i].lbl_packing != "") {
            count++;
          }
          // 重さの入力有無チェック
          if (this.changeList[i].weight != null && this.changeList[i].weight != "") {
            count++;
          }
          // サイズ（長さ）の入力有無チェック
          if (this.changeList[i].freightSizeL != null && this.changeList[i].freightSizeL != "") {
            count++;
          }
          // サイズ（幅）の入力有無チェック
          if (this.changeList[i].freightSizeW != null && this.changeList[i].freightSizeW != "") {
            count++;
          }
          // サイズ（高さ）の入力有無チェック
          if (this.changeList[i].freightSizeH != null && this.changeList[i].freightSizeH != "") {
            count++;
          }
          // 小口数の入力有無チェック
          if (
            this.changeList[i].smallLotsQuantity != null &&
            this.changeList[i].smallLotsQuantity != ""
          ) {
            count++;
          }
          // 輸送要件の入力有無チェック
          if (
            this.changeList[i].transportRequirement != null &&
            this.changeList[i].transportRequirement != ""
          ) {
            count++;
          }
          // 備考の入力有無チェック
          if (this.changeList[i].remarks != null && this.changeList[i].remarks != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.changeList[i].productName == null ||
              this.changeList[i].productName == "" ||
              this.changeList[i].packing == null ||
              this.changeList[i].packing == "" ||
              this.changeList[i].weight == null ||
              this.changeList[i].weight == "" ||
              this.changeList[i].freightSizeL == null ||
              this.changeList[i].freightSizeL == "" ||
              this.changeList[i].freightSizeW == null ||
              this.changeList[i].freightSizeW == "" ||
              this.changeList[i].freightSizeH == null ||
              this.changeList[i].freightSizeH == "" ||
              this.changeList[i].smallLotsQuantity == null ||
              this.changeList[i].smallLotsQuantity == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.changeList[i].listItemsErrorMsg = i18n.tc("check.chk_input");
              this.changeList[i].smallQtErrorMsg = i18n.tc("check.chk_input");
            } else {
              // 必須項目の未入力がない場合
              // 小口数以外の項目がすべて重複している場合、エラー
              let targets = [];
              targets = this.changeList.filter((element) => {
                return (
                  element.No !== this.changeList[i].No &&
                  element.productName === this.changeList[i].productName &&
                  element.packing === this.changeList[i].packing &&
                  element.weight === this.changeList[i].weight &&
                  element.freightSizeL === this.changeList[i].freightSizeL &&
                  element.freightSizeW === this.changeList[i].freightSizeW &&
                  element.freightSizeH === this.changeList[i].freightSizeH &&
                  JSON.stringify(element.transportRequirement) ===
                    JSON.stringify(this.changeList[i].transportRequirement) &&
                  element.remarks === this.changeList[i].remarks
                );
              });

              // 重複があった場合
              if (targets.length > 0) {
                duplicateFlg = true;
                for (const element of targets) {
                  // 小口数以外に項目重複のエラー表示
                  element.listItemsErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                  element.transportReqErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                  element.remarksErrorMsg = i18n.tc("check.chk_duplicate_billingItem");
                }
              }
            }
          }
        }

        if (errNo || duplicateFlg) {
          return;
        }
        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.errorFlg = false;
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-TOD-001_001_E");
          cnt = i + 1;
          countCheckFlg = true;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.changeList.length; i++) {
          if (this.changeList[i].productName) {
            var checkWeightNum = Number(
              commonUtil.zen2han(this.changeList[i].weight).replaceAll(",", "")
            );
            var checkSizeNumL = Number(
              commonUtil.zen2han(this.changeList[i].freightSizeL).replaceAll(",", "")
            );
            var checkSizeNumW = Number(
              commonUtil.zen2han(this.changeList[i].freightSizeW).replaceAll(",", "")
            );
            var checkSizeNumH = Number(
              commonUtil.zen2han(this.changeList[i].freightSizeH).replaceAll(",", "")
            );
            var checkLotsQtyNum = Number(
              commonUtil.zen2han(this.changeList[i].smallLotsQuantity).replaceAll(",", "")
            );

            // 数値チェック
            if (
              isNaN(checkWeightNum) ||
              isNaN(checkSizeNumL) ||
              isNaN(checkSizeNumW) ||
              isNaN(checkSizeNumH) ||
              isNaN(checkLotsQtyNum)
            ) {
              // 重さ、サイズ、小口数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (Number.isInteger(checkLotsQtyNum)) {
              // 処理なし
            } else {
              // 重さ、サイズ、小口数が整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (
              0 < checkWeightNum &&
              0 < checkSizeNumL &&
              0 < checkSizeNumW &&
              0 < checkSizeNumH &&
              0 < checkLotsQtyNum
            ) {
              // 処理なし
            } else {
              // 重さ、サイズ、小口数が0未満の場合
              cnt = i + 1;
            }
          }
        }
        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          this.ConfirmDialog.screenFlag = false;
          this.ConfirmDialog.changeFlag = true;
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.changeList.length; j++) {
              let checkPosition = String(j);
              if (!this.changeList[j].productName) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.weightCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeLCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeWCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.sizeHCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.smallLotsQtyCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 受注修正削除画面：受注削除ボタン押下
     */
    trnDel() {
      this.delFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-TOD-003_001_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /**
     * 受注修正削除画面：受注API（POST）
     */
    addTransport() {
      // メッセージ初期化
      this.pickupPointAlertMessage = "";
      this.pickupScheduleTimeAlertMessage = "";
      this.deliveryPointAlertMessage = "";
      this.deliveryDivAlertMessage = "";
      this.deliveryScheduleDateAlertMessage = "";
      this.deliveryScheduleTimeAlertMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_003;
      // 送り状SID
      body.reqIdv.invNumSid = this.searchInvNum;
      body.reqIdv.updateDatetime = this.updateDatetime;
      // 取引先
      body.reqIdv.clientCd = this.suppliersSelected;
      // 発注日
      body.reqIdv.orderDate = dateTimeHelper.convertUTC(this.orderDate);
      // 輸送担当営業所
      body.reqIdv.officeSid = this.officeSelected;

      body.reqIdv.businessNo = this.bussinessNoSelected;
      // 集荷元
      body.reqIdv.pickupPointCd = this.pickupPointCd;
      body.reqIdv.pickupPointSid = this.pickupPointSid;
      // 集荷区分
      body.reqIdv.pickupWayDiv = this.pickupDivSelected;
      // 集荷予定日
      body.reqIdv.pickupScheduleDate = dateTimeHelper.convertUTC(this.pickupScheduleDate);
      // 集荷予定時間From
      if (!this.pickupScheduleTimeFrom) {
        body.reqIdv.pickupScheduleFromDate = "";
      } else {
        const pickupSchduleFromDate = this.pickupScheduleDate + " " + this.pickupScheduleTimeFrom;
        body.reqIdv.pickupScheduleFromDate = dateTimeHelper.convertUTC(pickupSchduleFromDate);
      }
      // 集荷予定時間To
      if (!this.pickupScheduleTimeTo) {
        body.reqIdv.pickupSchedulToDate = "";
      } else {
        const pickupSchduleToDate = this.pickupScheduleDate + " " + this.pickupScheduleTimeTo;
        body.reqIdv.pickupScheduleToDate = dateTimeHelper.convertUTC(pickupSchduleToDate);
      }
      body.reqIdv.pickupSchedulToDate = this.pickupScheduleTimeTo;
      // 配達先
      body.reqIdv.deliveryPointCd = this.deliveryPointCd;
      body.reqIdv.deliveryPointSid = this.deliveryPointSid;
      // 配達区分
      body.reqIdv.deliveryWayDiv = this.deliveryDivSelected;
      // 配達予定日
      body.reqIdv.deliveryScheduleDate = dateTimeHelper.convertUTC(this.deliveryScheduleDate);
      // 配達予定時間From
      if (!this.deliveryScheduleTimeFrom) {
        body.reqIdv.deliveryScheduleFromDate = "";
      } else {
        const deliverySchduleFromDate =
          this.deliveryScheduleDate + " " + this.deliveryScheduleTimeFrom;
        body.reqIdv.deliveryScheduleFromDate = dateTimeHelper.convertUTC(deliverySchduleFromDate);
      }
      // 配達予定時間To
      if (!this.deliveryScheduleTimeTo) {
        body.reqIdv.deliveryScheduleToDate = "";
      } else {
        const deliveryScheduleToDate =
          this.deliveryScheduleDate + " " + this.deliveryScheduleTimeTo;
        body.reqIdv.deliveryScheduleToDate = dateTimeHelper.convertUTC(deliveryScheduleToDate);
      }
      // お客様問合せ番号
      body.reqIdv.inquiryNumber = this.inquiryNumber;
      // 対象/対象外
      body.reqIdv.vehiclePlanFlg = this.isTarget;
      // 非公開/公開
      body.reqIdv.privateFlg = this.isPrivate;

      if (this.addFlg) {
        // 削除フラグ(修正登録)
        body.reqIdv.deleteFlg = "0";
      } else if (this.delFlg) {
        // 削除フラグ（伝票削除）
        body.reqIdv.deleteFlg = "1";
      }
      // 受注明細List
      var bodyList = [];

      // 全てのレコードをPOSTする
      for (var i = 0; i < this.changeList.length; i++) {
        if (this.changeList[i].productName != "" && this.changeList[i].productName != null) {
          let inputMap = {};
          // 荷姿指示SID
          if (typeof this.changeList[i].packingSids === "undefined") {
            // 追加ボタンで追加したレコードの場合、荷姿指示SIDと更新日時は空の配列にする
            let packingSids = [];
            inputMap.packingSids = packingSids;
          } else {
            inputMap.packingSids = this.changeList[i].packingSids;
          }
          // 品名
          inputMap.itemName = this.changeList[i].productName;
          // 荷姿
          for (let m = 0; m < this.packingNacCdList.length; m++) {
            if (this.packingNacCdList[m].value == this.changeList[i].packing) {
              inputMap.packingNacCd = this.packingNacCdList[m].value;
            }
          }
          // 重量
          inputMap.totalWeight = this.changeList[i].weight.replaceAll(",", "");
          // サイズ
          inputMap.freightSizeL = this.changeList[i].freightSizeL.replaceAll(",", "");
          inputMap.freightSizeW = this.changeList[i].freightSizeW.replaceAll(",", "");
          inputMap.freightSizeH = this.changeList[i].freightSizeH.replaceAll(",", "");
          // 小口数
          inputMap.numOfSmallLot = this.changeList[i].smallLotsQuantity.replaceAll(",", "");
          // 輸送要件
          const transportRequirements = [];
          if (this.changeList[i].transportRequirement.length > 0) {
            for (const item of this.changeList[i].transportRequirement) {
              for (let m = 0; m < this.transportRequirementList.length; m++) {
                if (this.transportRequirementList[m].value == item) {
                  transportRequirements.push({
                    transportRequirement: this.transportRequirementList[m].value,
                  });
                }
              }
            }
          }
          inputMap.transportRequirements = transportRequirements;
          // 備考
          if (!this.changeList[i].remarks) {
            inputMap.remarks = "";
          } else {
            inputMap.remarks = this.changeList[i].remarks;
          }
          if (typeof this.changeList[i].pickupStatusDiv === "undefined") {
            inputMap.pickupStatusDiv = "00";
          } else {
            inputMap.pickupStatusDiv = this.changeList[i].pickupStatusDiv;
          }
          if (typeof this.changeList[i].deliveryStatusDiv === "undefined") {
            inputMap.deliveryStatusDiv = "00";
          } else {
            inputMap.deliveryStatusDiv = this.changeList[i].deliveryStatusDiv;
          }

          inputMap.deleteFlg = "0";

          bodyList.push(inputMap);
        }
      }
      // 削除分の明細データをbodyListに追加
      for (var x = 0; x < this.deleteList.length; x++) {
        let inputMapdel = {};
        // 荷姿指示SID
        inputMapdel.packingSids = this.deleteList[x].packingSids;
        // 品名
        inputMapdel.itemName = this.deleteList[x].productName;
        // 荷姿
        for (let m = 0; m < this.packingNacCdList.length; m++) {
          if (this.packingNacCdList[m].value == this.deleteList[x].packing) {
            inputMapdel.packingNacCd = this.packingNacCdList[m].value;
          }
        }
        // 重量
        if (this.deleteList[x].weight) {
          inputMapdel.totalWeight = this.deleteList[x].weight.replaceAll(",", "");
        }
        // サイズ
        if (this.deleteList[x].freightSizeL) {
          inputMapdel.freightSizeL = this.deleteList[x].freightSizeL.replaceAll(",", "");
        }
        if (this.deleteList[x].freightSizeW) {
          inputMapdel.freightSizeW = this.deleteList[x].freightSizeW.replaceAll(",", "");
        }
        if (this.deleteList[x].freightSizeH) {
          inputMapdel.freightSizeH = this.deleteList[x].freightSizeH.replaceAll(",", "");
        }
        // 小口数
        if (this.deleteList[x].smallLotsQuantity) {
          inputMapdel.numOfSmallLot = this.deleteList[x].smallLotsQuantity.replaceAll(",", "");
        }
        // 輸送要件
        const transportRequirements = [];
        if (this.deleteList[x].transportRequirement.length > 0) {
          for (const item of this.deleteList[x].transportRequirement) {
            for (let m = 0; m < this.transportRequirementList.length; m++) {
              if (this.transportRequirementList[m].value == item) {
                transportRequirements.push({
                  transportRequirement: this.transportRequirementList[m].value,
                });
              }
            }
          }
        }
        inputMapdel.transportRequirements = transportRequirements;
        // 備考
        if (!this.deleteList[x].remarks) {
          inputMapdel.remarks = "";
        } else {
          inputMapdel.remarks = this.deleteList[x].remarks;
        }
        // 削除フラグ
        inputMapdel.deleteFlg = "1";

        bodyList.push(inputMapdel);
      }
      body.reqIdv.packingDetails = bodyList;

      // ↓↓------- ここはローカル用-----------
      // this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      // this.infoDialog.isOpen = true;
      // this.infoDialog.outsideClickNotCloseFlg = true;
      // // 画面遷移ボタン
      // this.infoDialog.homePageFlag = true;
      // this.infoDialog.trnListFlg = true;
      // this.infoDialog.trnAddFlg = true;
      // this.infoDialog.firstPageFlag = false;

      // // ボタン識別フラグ
      // this.addFlg = false;
      // this.delFlg = false;
      // this.loadingCounter = 0;
      // ↑↑------------------

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // ここがGETとの違い。APIの接続方法としては同じ
          .securePost(appConfig.API_URL.BIZ_TRANSPORT_UPDATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addShpSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.trnListFlg = true;
              this.infoDialog.trnAddFlg = true;
              this.infoDialog.firstPageFlag = false;

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;

              resolve(response);

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.trnListFlg = false;
        this.infoDialog.trnAddFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     *輸送要件選択ダイアログを表示
     */
    openTransportRequirement(item, index) {
      // 輸送要件選択ダイアログのデータ
      for (let i = 0; i < this.transportRequirementList.length; i++) {
        // 選択済みのアイテムのみチェックをつける
        this.transportRequirementList[i].check = false;
        if (item.transportRequirement.includes(this.transportRequirementList[i].value)) {
          this.transportRequirementList[i].check = true;
        }
      }
      //輸送要件選択ボタン選択行インデックス
      this.clickRowIndex = index;
      this.dialogTransportRequirementsBreakdown = true;
    },
    /**
     * 輸送要件選択ダイアログ：閉じる処理
     */
    closeDialogTransportRequirements() {
      this.dialogTransportRequirementsBreakdown = false;
    },
    /**
     * 輸送要件選択ダイアログ：設定処理
     */
    setTransportRequirements() {
      //輸送要件選択ダイアログでチェックが入った行の指示内容を設定
      this.inputList[this.clickRowIndex].transportRequirement = this.transportRequirementList
        .filter((val) => val.check == true)
        .map((val) => {
          return val.value;
        });

      this.dialogTransportRequirementsBreakdown = false;
    },
    /**
     * 輸送担当営業所 プルダウンチェンジイベント
     */
    changeOffice(value) {
      // 輸送担当営業所を選択時
      let val = this.officeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintOffice = val.text;
        //this.transportOfficeInput = val.text;
      }
      this.officeList = this.officeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintOffice = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 集荷先 プルダウンチェンジイベント
     */
    changePickup(value) {
      this.pickupPointAlertMessage = "";
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.pickupPointCd = val.customerCode;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointCd = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 集荷方法区分フォーカスイベント
     * 変更前の値を取得しておく
     */
    focusPickupDiv() {
      this.prevPickupDivSelected = this.pickupDivSelected;
    },
    /**
     * 集荷方法区分 プルダウンチェンジイベント
     */
    changePickupDiv(value) {
      if (value == "03") {
        // 荷主出荷（集荷）に変更されたら、集荷元をクリアする
        this.pickupPointSelected = "";
        this.changePickup("");
        this.pickupPointAlertMessage = "";
      } else if (this.prevPickupDivSelected == "03") {
        // 荷主出荷（集荷）から他の値に変更されたら、集荷元をクリアする
        this.pickupPointSelected = "";
        this.changePickup("");
        this.pickupPointAlertMessage = "";
      }
    },
    /**
     * 配達方法区分 プルダウンチェンジイベント
     */
    changeDeliveryDiv(value) {
      if (value == "02") {
        if (this.deliveryPointSelected) {
          // 営業所止めで配達先が選択されている場合、APIを呼び出して取得しなおす
          this.getDeliverySelected(this.deliveryPointSelected, "sid");
        }
      }
    },
    /**
     * 配達先 プルダウンチェンジイベント
     */
    changeDelivery(value) {
      this.deliveryPointAlertMessage = "";
      // 配達先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.deliveryPointCd = val.customerCode;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointCd = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 戻るボタンのダイアログ、OK押下後処理
     */
    updateOk() {
      this.updateDialogMessage = false;
      if (this.errorFlg) {
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TOD_002,
          params: {
            searchParam: this.searchParam,
            checkFlg: true,
          },
        });
        this.updateDialogMessage = false;
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：キャンセル押下後処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 取引先リスト取得
     */
    getCustomInfo(val) {
      const params = {};
      // 取引先コード（前方一致）
      params.supplierCode = val;
      // 発注日(有効期限検索用)
      // params.orderDate = dateTimeHelper.convertUTC(this.orderDate);

      const clientList = getParameter.getTmsBizCustomerMst(params);
      // const clientList = [
      //   {
      //     value: "4400710",
      //     text: "4400710（ボルボ・カー・ジャパン㈱埼玉サービスセンター）",
      //     name: "ボルボ・カー・ジャパン㈱埼玉サービスセンター",
      //   },
      // ];
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先に表示するテキストを取得
          if (this.suppliersSelected) {
            const result = this.supplierList.find((v) => v.value === this.suppliersSelected);
            this.suppliersSelectedText = result.text;
            // 業務Noを設定
            this.getBussinessNoList();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getOfficeList(val, param) {
      // 選択した会社コードを取得
      // let supplierCd = this.supplierList.find(
      //   (element) => element.value === this.suppliersSelected
      // ).value;
      const params = {};
      // 取引先コード
      // params.customerCode = supplierCd;
      // 拠点管理種別（2=営業所を取得）
      params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";
      if (param === "sid") {
        // 拠点Sid
        params.baseSid = val;
      } else {
        // 拠点コード
        params.baseCode = val;
      }

      const officeListTmp = getParameter.getTmsBizBaseMst(params);
      // const officeListTmp = [
      //   {
      //     text: "001（日高営業所）",
      //     value: "2400000001",
      //     displayText: "日高営業所",
      //     name: "日高営業所",
      //     customerCode: "4400710",
      //   },
      // ];
      Promise.all([officeListTmp])
        .then((result) => {
          // 画面の初期値を設定
          this.officeList = result[0];
          // 集荷元選択済みの場合
          if (this.officeSelected) {
            let val = this.officeList.find((v) => v.value == this.officeSelected);
            if (val ?? false) {
              this.productHintOffice = val.text;
              //this.transportOfficeInput = val.text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getPickupSelected(val, param) {
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // 選択した会社コードを取得
      // let supplierCd = this.supplierList.find(
      //   (element) => element.value === this.suppliersSelected
      // ).value;
      const params = {};
      // 取引先コード
      // params.customerCode = supplierCd;
      // 拠点管理種別（集荷方法区分：自拠点出荷・荷主出荷（持込）の場合、2=営業所を取得）
      if (this.pickupDivSelected == "01" || this.pickupDivSelected == "02") {
        params.baseOwnerType = "2";
      } else {
        // そのほかの場合、1=拠点を取得
        params.baseOwnerType = "1";
      }
      // params.baseOwnerType = "1";
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      if (param === "sid") {
        // 拠点Sid
        params.baseSid = val;
      } else {
        // 拠点コード
        params.baseCode = val;
      }
      // 集荷予定日（有効期限検索用）
      params.validDate = dateTimeHelper.convertUTC(this.pickupScheduleDate);

      const pickupListTmp = getParameter.getTmsBizBaseMst(params);
      // const pickupListTmp = [
      //   {
      //     text: "001（日高営業所）",
      //     value: "2400000001",
      //     displayText: "日高営業所",
      //     name: "日高営業所",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "002（VCJ埼玉SC）",
      //     value: "2400000002",
      //     code: "4400710",
      //     name: "VCJ埼玉SC",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "003（VC東名横浜）",
      //     value: "2400000003",
      //     code: "4400710",
      //     name: "VC東名横浜",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "004（VC港北NT）",
      //     value: "2400000004",
      //     code: "4400710",
      //     name: "VC港北NT",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "005（VC目黒）",
      //     value: "2400000005",
      //     code: "4400710",
      //     name: "VC目黒",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "006（VC練馬）",
      //     value: "2400000006",
      //     code: "4400710",
      //     name: "VC練馬",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "007（VC杉並）",
      //     value: "2400000007",
      //     code: "4400710",
      //     name: "VC杉並",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "008（VC世田谷）",
      //     value: "2400000008",
      //     code: "4400710",
      //     name: "VC世田谷",
      //     customerCode: "4400710",
      //   },
      // ];

      Promise.all([pickupListTmp])
        .then((result) => {
          // 画面の初期値を設定
          this.pickupPointList = result[0];
          // 集荷元選択済みの場合
          if (this.pickupPointSelected) {
            let val = this.pickupPointList.find((v) => v.value == this.pickupPointSelected);
            if (val ?? false) {
              this.productHintPickup = val.text;
              this.pickupPointCd = val.customerCode;
              this.pickupPointSid = val.value;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getDeliverySelected(val, param) {
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // 選択した会社コードを取得
      // let supplierCd = this.supplierList.find(
      //   (element) => element.value === this.suppliersSelected
      // ).value;
      const params = {};
      // 取引先コード
      // params.customerCode = supplierCd;
      // 拠点管理種別（配達方法区分：営業所止めの場合、2=営業所を取得）
      if (this.deliveryDivSelected == "02") {
        params.baseOwnerType = "2";
      }
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      if (param === "sid") {
        // 拠点Sid
        params.baseSid = val;
      } else {
        // 拠点コード
        params.baseCode = val;
      }
      // 配達予定日（有効期限検索用）
      params.validDate = dateTimeHelper.convertUTC(this.deliveryScheduleDate);

      const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
      // const deliveryListTmp = [
      //   {
      //     text: "001（日高営業所）",
      //     value: "2400000001",
      //     displayText: "日高営業所",
      //     name: "日高営業所",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "002（VCJ埼玉SC）",
      //     value: "2400000002",
      //     code: "4400710",
      //     name: "VCJ埼玉SC",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "003（VC東名横浜）",
      //     value: "2400000003",
      //     code: "4400710",
      //     name: "VC東名横浜",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "004（VC港北NT）",
      //     value: "2400000004",
      //     code: "4400710",
      //     name: "VC港北NT",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "005（VC目黒）",
      //     value: "2400000005",
      //     code: "4400710",
      //     name: "VC目黒",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "006（VC練馬）",
      //     value: "2400000006",
      //     code: "4400710",
      //     name: "VC練馬",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "007（VC杉並）",
      //     value: "2400000007",
      //     code: "4400710",
      //     name: "VC杉並",
      //     customerCode: "4400710",
      //   },
      //   {
      //     text: "008（VC世田谷）",
      //     value: "2400000008",
      //     code: "4400710",
      //     name: "VC世田谷",
      //     customerCode: "4400710",
      //   },
      // ];

      Promise.all([deliveryListTmp])
        .then((result) => {
          // 画面の初期値を設定
          this.deliveryPointList = result[0];
          // 配達先選択済みの場合
          if (this.deliveryPointSelected) {
            let val = this.deliveryPointList.find((v) => v.value == this.deliveryPointSelected);
            if (val ?? false) {
              this.productHintDelivery = val.text;
              this.deliveryPointCd = val.customerCode;
              this.deliveryPointSid = val.value;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 受注修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    /**
     * 追加モーダルからリスト取得
     */
    parentMethod: function (target) {
      this.updateDialogMessage = false;
      // 追加モーダルのリストをキャッシュに追加
      for (var i = 0; i < target.length; i++) {
        target[i].isEditable = true;
        this.changeList.push({ ...Object.assign({}, target[i]) });
      }
      // 追加分に応じてページ追加
      if (Math.ceil(this.changeList.length / this.itemsPerPage) > this.totalPage) {
        this.totalPage += 1;
      }
      this.totalRecord = this.changeList.length;
      this.useCache();
      this.$nextTick(() => {
        this.$refs.editedList.validate();
      });
    },

    useCache() {
      // 初期ページの場合inputListをキャッシュ用リストに格納
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }

      if (this.changeList.length == 0) {
        this.inputList = [];
      } else {
        // 選択中のページに表示する行のみinputListに入れる処理
        for (var i = 0; i < this.changeList.length / this.itemsPerPage; i++) {
          // 選択中のページの先頭となる項目の場合
          if (
            this.changeList[i * this.itemsPerPage].No ==
            (this.page - 1) * this.itemsPerPage + 1
          ) {
            this.inputList = [];
            // ページ表示数分、表示用一覧リストの配列に入れていく
            for (var j = 0; j < this.itemsPerPage; j++) {
              // Noが選択中ページ内の場合
              if (
                this.changeList[i * this.itemsPerPage + j].No ==
                (this.page - 1) * this.itemsPerPage + 1 + j
              ) {
                this.inputList.push(this.changeList[i * this.itemsPerPage + j]);
              }
              if (this.changeList[i * this.itemsPerPage + j].No == this.changeList.length) {
                break;
              }
            }

            break;
          }
        }
      }
    },
    // 選択済み荷姿のテキストを返却
    getPackingText(val) {
      for (const item of this.packingNacCdList) {
        if (val === item.value) {
          return item.text;
        }
      }
    },
    getBussinessNoList() {
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};
      // 事業部・営業所コード
      params.officeCode = sessionStorage.getItem("office_cd");
      // 取引先コード
      params.requesterCustomerCode = this.suppliersSelected;

      // 業務マスタ取得
      const bussinessNoList = getParameter.getOperationMst(params);
      // const bussinessNoList = [
      //   {
      //     value: "440710",
      //     name: "ＶＣＪ四輪輸送",
      //     text: "440710（ＶＣＪ四輪輸送）",
      //   },
      //   {
      //     value: "440711",
      //     name: "ＶＣＪ有料道路代",
      //     text: "440711（ＶＣＪ四輪輸送有料道路代）",
      //   },
      //   {
      //     value: "440712",
      //     name: "ＶＣＪチャーター",
      //     text: "440712（ＶＣＪチャーター）",
      //   },
      //   {
      //     value: "440713",
      //     name: "ＶＣＪ鉄板費用",
      //     text: "440713（ＶＣＪ鉄板費用）",
      //   },
      // ];

      Promise.all([bussinessNoList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.bussinessNoList = result[0];
          if (this.bussinessNoSelected) {
            const val = this.bussinessNoList.find((v) => v.value == this.bussinessNoSelected);
            if (val ?? false) {
              this.bussinessNoSelectedText = val.text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 請求No(業務No)ヒント取得処理
    setBussinessNo() {
      for (let i = 0; i < this.bussinessNoList.length; i++) {
        if (this.bussinessNoList[i].value == this.bussinessNoSelected) {
          return this.bussinessNoList[i].name;
        }
      }
    },

    // サイズ値のチェック
    chkSizeValue(val) {
      var freightSize;
      if (val == null || val == "") {
        freightSize = "";
      } else {
        freightSize = String(commonFunction.getDelimiterFew(Number(val, 9, 2)));
      }
      return freightSize;
    },
  },
  computed: {
    // 時間入力欄の時計アイコンの出し分け
    type() {
      if (!this.isDisabledDelBtn) {
        return "time";
      } else {
        return "text";
      }
    },
  },
  watch: {
    orderDateCal: {
      handler(val) {
        this.orderDate = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateCal: {
      handler(val) {
        this.pickupScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateCal: {
      handler(val) {
        this.deliveryScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    orderDate: {
      handler() {
        // 発注日のチェック
        if (dateTimeHelper.isDisplayDateFormat(this.orderDate) && this.suppliersSelected) {
          // 発注日が正しい日付かつ、取引先選択済みの場合、取引先リストを取得
          // this.getCustomInfo(this.suppliersSelected);
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    pickupScheduleDate: {
      handler() {
        // 集荷予定日のチェック
        if (
          dateTimeHelper.isDisplayDateFormat(this.pickupScheduleDate) &&
          this.pickupPointSelected
        ) {
          // 集荷予定日が正しい日付かつ、集荷元選択済みの場合、集荷元リストを再取得する
          this.getPickupSelected(this.pickupPointSelected, "sid");
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    deliveryScheduleDate: {
      handler() {
        // 配達予定日のチェック
        if (
          dateTimeHelper.isDisplayDateFormat(this.deliveryScheduleDate) &&
          this.deliveryPointSelected
        ) {
          // 配達予定日が正しい日付かつ、配達先選択済みの場合、配達先を取得する
          this.getDeliverySelected(this.deliveryPointSelected, "sid");
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    /**
     * 輸送担当営業所取得
     */
    officeInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.officeList = [];
        //   this.officeSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 輸送担当営業所取得
          this.getOfficeList(val);
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },
    /**
     * 集荷元取得
     */
    pickupInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // 初期値設定時に「コード(拠点名)」でリスト検索してしまうのを回避
        if (this.isPickupInit) {
          this.isPickupInit = false;
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.pickupPointList = [];
        //   this.pickupPoint = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 集荷元リスト取得
          this.getPickupSelected(val);
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    /**
     * 配達先取得
     */
    deliveryInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 初期値設定時に「コード(拠点名)」でリスト検索してしまうのを回避
        if (this.isdeliveryInit) {
          this.isdeliveryInit = false;
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPoint = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          this.getDeliverySelected(val);
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    pickupPointList() {
      // 集荷元リストが空になった場合、前選択した値をクリア
      if (this.pickupPointList.length === 0) {
        this.pickupPointSelected = "";
      }
    },
    deliveryPointList() {
      // 配達先リストが空になった場合、前選択した値をクリア
      if (this.deliveryPointList.length === 0) {
        this.deliveryPointSelected = "";
      }
    },
    page: function (newValue) {
      this.page = newValue;
      this.useCache();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

// サイズの入力部分と×の間を狭める
#listData ::v-deep th:nth-child(5),
#listData ::v-deep td:nth-child(5) {
  padding-right: 0px;
}
#listData ::v-deep th:nth-child(7),
#listData ::v-deep td:nth-child(7) {
  padding: 0px;
}
#listData ::v-deep th:nth-child(9),
#listData ::v-deep td:nth-child(9) {
  padding-left: 0px;
}
#listData ::v-deep th:nth-child(6),
#listData ::v-deep td:nth-child(6) {
  padding: 0px 0px 5px 0px;
}
#listData ::v-deep th:nth-child(8),
#listData ::v-deep td:nth-child(8) {
  padding: 0px 0px 5px 0px;
}
.lbl-screen-name {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: #000;
  margin-left: -15px;
}

.add-textbox-remark {
  width: 68rem;
}

.size-div {
  display: flex;
  justify-content: center;
}

.error-message {
  color: #ff5252;
  font-size: 12px;
}
</style>
