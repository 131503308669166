<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_diapatchCsvImportError") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <p></p>
      <v-row>
        <div class="search-row-exeBtn first-search-item">
          <div class="btn-area">
            <!-- 画面クリアボタン -->
            <v-btn class="other-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        :options.sync="sortOptions"
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- 依頼番号 -->
        <template v-slot:[`item.requestNo`]="{ item }">
          <div class="listNo-style">{{ item.requestNo }}</div>
        </template>

        <!--エラーデータ -->
        <template v-slot:[`item.value`]="{ item }">
          <div class="listNo-style">{{ item.value }}</div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  name: appConfig.SCREEN_ID.P_SHP_002,
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ソート
    sortOptions: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        width: "5%",
        value: "no",
        sortable: true,
        align: "left",
      },
      // 依頼番号
      {
        text: i18n.tc("label.lbl_requestNo"),
        value: "requestNo",
        width: "20%",
        sortable: true,
        align: "left",
      },
      // 行
      {
        text: i18n.tc("label.lbl_rows"),
        value: "row",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 列
      {
        text: i18n.tc("label.lbl_column"),
        value: "column",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // エラー内容
      {
        text: i18n.tc("label.lbl_errorContent"),
        value: "message",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // エラーデータ
      {
        text: i18n.tc("label.lbl_errorData"),
        value: "value",
        width: "20%",
        align: "left",
        sortable: true,
      },
    ],
    // 明細リスト
    inputList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.inputList = [];
      for (var i = 0; i < this.entity.list.length; i++) {
        this.inputList.push({
          no: "",
          requestNo: this.entity.list[i].requestNo,
          row: this.entity.list[i].row,
          column: this.entity.list[i].column,
          message: this.entity.list[i].message,
          value: this.entity.list[i].value,
        });
      }
      // 連番
      for (var n = 0; n < this.inputList.length; n++) {
        this.inputList[n].no = n + 1;
      }
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "requestNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.listNo < b.listNo) {
                  return -1;
                } else if (a.listNo > b.listNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.listNo > b.listNo) {
                  return -1;
                } else if (a.listNo < b.listNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "row") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.row - b.row;
              } else {
                return b.row - a.row;
              }
            });
          } else if (sortBy == "column") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.column - b.column;
              } else {
                return b.column - a.column;
              }
            });
          } else if (sortBy == "message") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.message < b.message) {
                  return -1;
                } else if (a.message > b.message) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.message > b.message) {
                  return -1;
                } else if (a.message < b.message) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "value") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.value < b.value) {
                  return -1;
                } else if (a.value > b.value) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.value > b.value) {
                  return -1;
                } else if (a.value < b.value) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

// .pad {
//   padding-left: 0;
//   padding-right: 0;
// }

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
