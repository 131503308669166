<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_costMasterList" />
      <!-- 標準原価マスタ一覧 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 車両 -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="vehiclesSelected"
                  :items="dummy_vehicleList"
                  :label="$t('label.lbl_vehicle')"
                  :hint="setSrhVehicle()"
                  class="txt-single"
                  @input="inputVehicle"
                ></v-autocomplete>
              </div>

              <div class="search-autocomplete search-item-info" style="float: left; width: 800px">
                <v-row>
                  <v-col cols="2" style="text-align: left">
                    <div style="font-size: 15px">&emsp;{{ formatLbl }}</div>
                    <div style="font-size: 15px">&emsp;{{ wdhLbl }}</div>
                  </v-col>
                  <v-col style="text-align: left; width: 30%">
                    <div style="font-size: 15px">{{ formatItem }}</div>
                    <div style="font-size: 15px">{{ wdhItem }}</div>
                  </v-col>
                  <v-col cols="2" style="text-align: left">
                    <div style="font-size: 15px">&emsp;{{ vehicleWeightLbl }}</div>
                    <div style="font-size: 15px">&emsp;{{ maximumPayloadLbl }}</div>
                  </v-col>
                  <v-col style="text-align: left; width: 30%">
                    <div style="font-size: 15px">{{ vehicleWeightItem }}</div>
                    <div style="font-size: 15px">{{ maximumPayloadItem }}</div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="search">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            :hide-default-footer="true"
            height="511px"
          >
            <!-- ヘッダ項目 -->
            <!-- 車両 -->
            <template v-slot:[`header.vehicle`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 変動費 -->
            <template v-slot:[`header.variableCosts`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>変動費</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">1kg当り</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実車率 -->
            <template v-slot:[`header.actualVehicleRate`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`header.actualVariableCostTotal`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">1kg当り</div>
                </v-col>
              </v-row>
            </template>
            <!-- 固定費 -->
            <template v-slot:[`header.fixedCosts`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>固定費</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">1kg当り</div>
                </v-col>
              </v-row>
            </template>
            <!-- 稼働率 -->
            <template v-slot:[`header.occupancyRate`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`header.actualVariableCostTotal2`]="{ header }">
              <v-row>
                <v-col style="text-align: left">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">1kg当り</div>
                </v-col>
              </v-row>
            </template>
            <!-- 明細項目 -->
            <!-- 車両 -->
            <template v-slot:[`item.vehicle`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.lbl_vehicle"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_vehicle')"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.vehicle }}</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- 変動費 -->
            <template v-slot:[`item.variableCosts`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.variableCosts"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="変動費"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.variableCosts }}</div>
            </template>
            <!-- 実車率 -->
            <template v-slot:[`item.actualVehicleRate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.actualVehicleRate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="実車率"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.actualVehicleRate }}</div>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`item.actualVariableCostTotal`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.actualVariableCostTotal"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="実変動費計"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.actualVariableCostTotal }}</div>
            </template>
            <!-- 固定費 -->
            <template v-slot:[`item.fixedCosts`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.fixedCosts"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="固定費"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.fixedCosts }}</div>
            </template>
            <!-- 稼働率 -->
            <template v-slot:[`item.occupancyRate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.occupancyRate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="稼働率"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.occupancyRate }}</div>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`item.actualVariableCostTotal2`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.actualVariableCostTotal2"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="実変動費計"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.actualVariableCostTotal2 }}</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_BIL_CONTRACT_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    singleSelect: false,
    pListPopup: false,
    alertMessage: "",

    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 車両
    vehiclesSelected: "",
    // 形式
    formatLbl: "",
    formatItem: "",
    // 長さ/幅/高さ
    wdhLbl: "",
    wdhItem: "",
    // 車両重量
    vehicleWeightLbl: "",
    vehicleWeightItem: "",
    // 最大積載重量
    maximumPayloadLbl: "",
    maximumPayloadItem: "",
    // 初期データ
    defaultData: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // ヘッダ
    headerItems: [
      // 車両
      {
        text: i18n.tc("label.lbl_vehicle"),
        value: "vehicle",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 変動費・変動費計
      {
        text: "変動費計",
        value: "variableCosts",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 実車率
      {
        text: "実車率",
        value: "actualVehicleRate",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 実変動費計
      {
        text: "実変動費計",
        value: "actualVariableCostTotal",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 固定費・固定費計
      {
        text: "固定費計",
        value: "fixedCosts",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 稼働率
      {
        text: "稼働率",
        value: "occupancyRate",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 実変動費計
      {
        text: "実変動費計",
        value: "actualVariableCostTotal2",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "3%",
        align: "center",
        sortable: false,
      },
    ],
    detailDialogData: {},
    dummy_inputList: [
      {
        vehicle: "大型10t",
        variableCosts: "¥53.50",
        actualVehicleRate: "66%",
        actualVariableCostTotal: "¥81.06",
        fixedCosts: "¥4,426",
        occupancyRate: "66%",
        actualVariableCostTotal2: "¥6,707",
      },
    ],
    inputList: [],
    dummy_vehicleList: [
      { text: "", value: "", name: "" },
      { text: "大型10t", value: "10t", name: "" },
    ],
    vehicleList: [],
    defaultItem: {
      vehicle: "",
      variableCosts: "",
      actualVehicleRate: "",
      actualVariableCostTotal: "",
      fixedCosts: "",
      occupancyRate: "",
      actualVariableCostTotal2: "",
    },
    apiReqParam: {
      clientSid: "",
      utcDate: "",
    },
    rules: {},
  }),
  methods: {
    // 検索ボタン
    search(isInit) {
      if (this.$refs.form.validate() || isInit) {
        this.getBusinessSpecificationsMaster();
      }
    },

    // 車両API接続
    getvehicleList(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 車両
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.vehicleList = result[0];
          // 車両が1件の場合、車両を自動選択する
          //this.vehicleList.length == 1 ? (this.vehiclesSelected = this.vehicleList[0].value) : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.vehiclesSelected = "";
      this.getvehicleList();
      this.billingType = "";
      this.txt_billingCode = "";
      this.situation = "";
      this.inputList = [];
      let param = this.$route.params.selectData;
      if (param != null && !this.clearFlg) {
        this.vehiclesSelected = param;
        this.search(true);
      }
    },

    jumpTo(item) {
      if (this.$refs.form.validate()) {
        item.vehiclesSelected = this.inputList[0].clientSid;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CLA_002,
          params: {
            selectData: item,
          },
        });
      }
    },

    // 車両コードリストボックス取得処理
    setSrhVehicle() {
      for (var i = 0; i < this.dummy_vehicleList.length; i++) {
        if (this.dummy_vehicleList[i].value == this.vehiclesSelected) {
          return this.dummy_vehicleList[i].name;
        }
      }
    },

    /**
     *  車両変更時処理(車両情報リストボックス)
     */
    inputVehicle() {
      if (this.vehiclesSelected == "") {
        this.formatLbl = "";
        this.wdhLbl = "";
        this.vehicleWeightLbl = "";
        this.maximumPayloadLbl = "";
        this.formatItem = "";
        this.wdhItem = "";
        this.vehicleWeightItem = "";
        this.maximumPayloadItem = "";
      } else {
        this.formatLbl = "形式";
        this.wdhLbl = "長さ/幅/高さ";
        this.vehicleWeightLbl = "車両重量";
        this.maximumPayloadLbl = "最大積載重量";
      }
      if (this.vehiclesSelected == "10t") {
        this.formatItem = "：KC-FK617K";
        this.wdhItem = "：8.44m/2.49m/3.54m";
        this.vehicleWeightItem = "：10t";
        this.maximumPayloadItem = "：3.4t";
      }
    },

    /**
     * 原価マスタ取得
     */
    getBusinessSpecificationsMaster() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.vehiclesSelected;
      config.params.reqComReferenceDate = dateTimeHelper.convertJST();
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // console.debug("getBusinessSpecificationsMaster() Config", config);
      // return new Promise((resolve, reject) => {
      //   this.$httpClient
      //     // 接続先のAPI_URLを入力
      //     .secureGet(appConfig.API_URL.BIZ_BIL_CONTRACT, config)
      //     .then((response) => {
      //       const jsonData = JSON.parse(JSON.stringify(response.data));
      //       // console.debug("getBusinessSpecificationsMaster() Response", response);
      //       // 正常時
      //       if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
      //         // ページング
      //         this.pageCount = jsonData.resCom.resComPagination.totalPage;
      //         this.totalCount = jsonData.resCom.resComPagination.totalRecord;
      //         const list = [];
      //         jsonData.resIdv.billContract.forEach((row) => {
      //           list.push({
      //             bussinessNo: row.businessNo,
      //             variableCosts: row.gggnak,
      //             actualVehicleRate: row.ggsime,
      //             actualVariableCostTotal: row.ggsiha,
      //             clientSid: row.clientSid,
      //           });
      //         });
      //         this.inputList = list;
      //         resolve(list);
      //         // エラー時
      //       } else {
      //         reject(jsonData.resCom.resComMessage);
      //       }
      //     })
      //     .catch((resolve) => {
      //       console.error("getBusinessSpecificationsMaster() Resolve", resolve);
      //       reject(messsageUtil.getMessage("P-999-999_999_E"));
      //     })
      //     .finally(() => {
      //       // ローディング画面表示OFF
      //       this.loadingCounter = 0;
      //     });
      // });
      if (this.vehiclesSelected == "10t") {
        this.inputList.push({
          vehicle: "大型10t",
          variableCosts: "¥53.50",
          actualVehicleRate: "66%",
          actualVariableCostTotal: "¥81.06",
          fixedCosts: "¥4,426",
          occupancyRate: "66%",
          actualVariableCostTotal2: "¥6,707",
        });
      }
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    constractStartDateToCal(val) {
      this.constractStartDateTo = this.formatDate(val);
    },
    constractStartDateFromCal(val) {
      this.constractStartDateFrom = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getBusinessSpecificationsMaster();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}

.toatlNum {
  margin-left: 10px;
}

.v-data-table {
  white-space: pre-line;
}
</style>
