<template>
  <div style="background: white">
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_costMasterDetail" />
      <v-container fluid>
        <v-row>
          <!-- 変動費詳細 -->
          <v-col cols="6">
            <div>
              <p class="cost-div-name">変動費</p>
              <table border="2" class="detail-table">
                <thead>
                  <tr>
                    <th rowspan="2">
                      <p>変動費計</p>
                      <p class="detail-th-sub">1km当り</p>
                    </th>
                    <th valign="top">実車率</th>
                    <th rowspan="2">
                      <p>実変動費計</p>
                      <p class="detail-th-sub">1km当り</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td :class="getVariableCostTdClass()">￥53.50</td>
                    <td>
                      <v-text-field
                        single-line
                        outlined
                        hide-details="auto"
                        suffix="%"
                        class="detail-tf"
                        dense
                      ></v-text-field>
                    </td>
                    <td :class="getVariableCostTdClass()">￥81.06</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
          <!-- 固定費詳細 -->
          <v-col cols="6">
            <div>
              <p class="cost-div-name">固定費</p>
              <table
                border="2"
                style="
                  width: 100%;
                  border-collapse: collapse;
                  border-color: black;
                  table-layout: fixed;
                "
                class="detail-table"
              >
                <thead>
                  <tr>
                    <th rowspan="2">
                      <p>固定費計</p>
                      <p class="detail-th-sub">1km当り</p>
                    </th>
                    <th valign="top">実車率</th>
                    <th rowspan="2">
                      <p>実固定費計</p>
                      <p class="detail-th-sub">1km当り</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td :class="getFixedCostTdClass()">￥4,426</td>
                    <td>
                      <v-text-field
                        single-line
                        outlined
                        hide-details="auto"
                        suffix="%"
                        class="detail-tf"
                        dense
                      ></v-text-field>
                    </td>
                    <td :class="getFixedCostTdClass()">￥6,707</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <!-- 内訳トグル -->
            <div style="float: left">
              <v-btn-toggle
                v-model="toogleCostDiv"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn class="paid-btn" style="width: auto" @click="showVariableCostDetail()"
                  >変動費内訳</v-btn
                >
                <v-btn class="paid-btn" style="width: auto" @click="showFixedCostDetail()"
                  >固定費内訳</v-btn
                >
              </v-btn-toggle>
            </div>
            <!-- コピー/登録 -->
            <div style="float: right; display: flex">
              <v-autocomplete
                dense
                class="txt-single"
                label="コピー対象車両"
                :items="['大型10t', '大型12t', '大型14t']"
                persistent-hint
              ></v-autocomplete>
              <!-- 車両データコピーボタン -->
              <v-btn color="primary" class="other-btn" @click="copy()"
                >{{ $t("btn.btn_copy") }}
              </v-btn>
              <!-- 登録ボタン -->
              <v-btn
                color="primary"
                class="api-btn"
                @click="displayRegistrationConfirmationDialog()"
                >{{ $t("btn.btn_insert") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- 詳細データテーブル -->
      <v-container fluid class="main-area">
        <v-data-table
          id="costData"
          fixed-header
          :headers="headers"
          :items="items"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          :items-per-page="50"
        >
          <template v-slot:[`item`]="{ item }">
            <tr :class="{ 'cost-tr-subtotal': item.costItemCd === 'C9000' }">
              <td>{{ item.costItemCd }}</td>
              <td>{{ item.costItemName }}</td>
              <td>{{ item.costItemDetailCd }}</td>
              <td>{{ item.costItemDetailName }}</td>
              <td>
                <v-text-field
                  single-line
                  outlined
                  hide-details="auto"
                  class="detail-tf"
                  dense
                  v-model="item.unitPrice"
                  v-if="item.costItemCd != 'C9000'"
                ></v-text-field>
              </td>
              <td>{{ item.unitName }}</td>
              <td class="text-left">
                <v-btn small @click="editExpireDate()" text v-if="item.costItemCd != 'C9000'">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td class="text-right">{{ item.cost }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-container>

      <!-- 有効期限設定モーダル -->
      <v-dialog v-model="isOpenEditExpireDateDialog" :max-width="1300" persistent>
        <editExpireDateDialog
          :isOpenDetailDialog.sync="isOpenEditExpireDateDialog"
          :selectedExpireDateList="selectedExpireDateList"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
          :_deleteList="deleteList"
          :fiveInDiv="fiveInDiv"
          v-on:child-event="parentMethod"
        />
      </v-dialog>

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :changeFlag="infoDialog.changeFlag"
        :distProcPlanAddFlg="infoDialog.distProcPlanAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="infoDialog.okAction"
      />

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import editExpireDateDialog from "./MstCostEditExpireDate";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: appConfig.SCREEN_ID.P_CLA_002,
  components: {
    Loading,
    sideMenu,
    NavBar,
    editExpireDateDialog,
    ConfirmDialog,
    SimpleDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 初期データ
    defaultData: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // ボタントグル
    toogleCostDiv: 0,
    // 表示中の区分 (変動費/固定費)
    currentCostDiv: "",
    costDivName10: "変動費",
    costDivName20: "固定費",
    // データテーブル ヘッダー
    headers: [],
    // データテーブル アイテム
    items: [],

    baseHeaders: [
      {
        text: "原価項目CD",
        value: "costItemCd",
        width: "auto",
        align: "left",
        sortable: false,
      },
      {
        text: "原価項目名",
        value: "costItemName",
        width: "auto",
        align: "left",
        sortable: false,
      },
      {
        text: "原価項目明細CD",
        value: "costItemDetailCd",
        width: "auto",
        align: "left",
        sortable: false,
      },
      {
        text: "原価項目明細名",
        value: "costItemDetailName",
        width: "auto",
        align: "left",
        sortable: false,
      },
      {
        text: "単価",
        value: "unitPrice",
        width: "10%",
        align: "left",
        sortable: false,
      },
      {
        text: "単位",
        value: "unitName",
        width: "auto",
        align: "left",
        sortable: false,
      },
      {
        text: "有効期限",
        value: "validityPeriod",
        width: "auto",
        align: "center",
        sortable: false,
      },
    ],

    variableCostHeaders: {
      text: "変動費",
      value: "cost",
      width: "auto",
      align: "left",
      sortable: false,
    },

    fixedCostHeaders: {
      text: "固定費",
      value: "cost",
      width: "auto",
      align: "left",
      sortable: false,
    },

    variableCostDetails: [
      {
        costItemCd: "C0001",
        costItemName: "燃料費",
        costItemDetailCd: "CI0001",
        costItemDetailName: "ℓ当たり購入金額",
        unitPrice: "120",
        unitName: "円",
        unitCd: "1",
      },
      {
        costItemCd: "C0001",
        costItemName: "燃料費",
        costItemDetailCd: "CI0002",
        costItemDetailName: "ℓ当たり燃費",
        unitPrice: "3",
        unitName: "km",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "40",
      },
      {
        costItemCd: "C0002",
        costItemName: "油脂費",
        costItemDetailCd: "CI0003",
        costItemDetailName: "1回当たり交換費用",
        unitPrice: "7873.4",
        unitName: "円",
      },
      {
        costItemCd: "C0002",
        costItemName: "油脂費",
        costItemDetailCd: "CI0004",
        costItemDetailName: "標準交換距離",
        unitPrice: "15000",
        unitName: "km",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "0.52",
      },
      {
        costItemCd: "C0003",
        costItemName: "タイヤ費",
        costItemDetailCd: "CI0005",
        costItemDetailName: "1回当たり交換費用",
        unitPrice: "138000",
        unitName: "円",
      },
      {
        costItemCd: "C0003",
        costItemName: "タイヤ費",
        costItemDetailCd: "CI0006",
        costItemDetailName: "標準交換距離",
        unitPrice: "18000",
        unitName: "km",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "7.67",
      },
      {
        costItemCd: "C0004",
        costItemName: "修繕費",
        costItemDetailCd: "CI0007",
        costItemDetailName: "年間修繕費",
        unitPrice: "272000",
        unitName: "円",
      },
      {
        costItemCd: "C0004",
        costItemName: "修繕費",
        costItemDetailCd: "CI0008",
        costItemDetailName: "年間走行距離",
        unitPrice: "54000",
        unitName: "km",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "5.04",
      },
      {
        costItemCd: "C0005",
        costItemName: "尿素水費",
        costItemDetailCd: "CI0009",
        costItemDetailName: "ℓ当たり購入金額",
        unitPrice: "73.2",
        unitName: "円",
      },
      {
        costItemCd: "C0005",
        costItemName: "尿素水費",
        costItemDetailCd: "CI0010",
        costItemDetailName: "ℓ当たり走行距離",
        unitPrice: "270",
        unitName: "km",
      },
      {
        costItemCd: "C0005",
        costItemName: "尿素水費",
        costItemDetailCd: "CI0011",
        costItemDetailName: "フィルター交換金額",
        unitPrice: "",
        unitName: "円",
      },
      {
        costItemCd: "C0005",
        costItemName: "尿素水費",
        costItemDetailCd: "CI0012",
        costItemDetailName: "フィルター交換距離",
        unitPrice: "",
        unitName: "km",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "0.27",
      },
    ],

    fixedCostDetails: [
      {
        costItemCd: "C1001",
        costItemName: "車両費",
        costItemDetailCd: "CI1001",
        costItemDetailName: "車両購入費",
        unitCd: "1",
        unitPrice: "8400000",
        unitName: "円",
      },
      {
        costItemCd: "C1001",
        costItemName: "車両費",
        costItemDetailCd: "CI1002",
        costItemDetailName: "償却月数",
        unitCd: "3",
        unitPrice: "60",
        unitName: "月数",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "140000",
      },
      {
        costItemCd: "C1002",
        costItemName: "税金",
        costItemDetailCd: "CI1003",
        costItemDetailName: "取得税・環境性能割",
        unitCd: "1",
        unitPrice: "158000",
        unitName: "円",
      },
      {
        costItemCd: "C1002",
        costItemName: "税金",
        costItemDetailCd: "CI1004",
        costItemDetailName: "自動車税",
        unitCd: "1",
        unitPrice: "9000",
        unitName: "円",
      },
      {
        costItemCd: "C1002",
        costItemName: "税金",
        costItemDetailCd: "CI1005",
        costItemDetailName: "月数",
        unitCd: "3",
        unitPrice: "12",
        unitName: "月数",
      },
      {
        costItemCd: "C1002",
        costItemName: "税金",
        costItemDetailCd: "CI1006",
        costItemDetailName: "重量税",
        unitCd: "1",
        unitPrice: "20800",
        unitName: "円",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "5116",
      },
      {
        costItemCd: "C1003",
        costItemName: "自賠責保険",
        costItemDetailCd: "CI1007",
        costItemDetailName: "自賠責保険料",
        unitCd: "1",
        unitPrice: "121743",
        unitName: "円",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "10145",
      },
      {
        costItemCd: "C1004",
        costItemName: "人件費",
        costItemDetailCd: "CI1008",
        costItemDetailName: "総人件費",
        unitCd: "1",
        unitPrice: "5200000",
        unitName: "円",
      },
      {
        costItemCd: "C1004",
        costItemName: "人件費",
        costItemDetailCd: "CI1009",
        costItemDetailName: "総稼働時間",
        unitCd: "4",
        unitPrice: "2772",
        unitName: "時間",
      },
      {
        costItemCd: "C1004",
        costItemName: "人件費",
        costItemDetailCd: "CI1010",
        costItemDetailName: "1ヶ月稼働時間",
        unitCd: "4",
        unitPrice: "231",
        unitName: "時間",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "140000",
      },
      {
        costItemCd: "C1005",
        costItemName: "間接費",
        costItemDetailCd: "CI1011",
        costItemDetailName: "総間接費",
        unitCd: "5",
        unitPrice: "70000000",
        unitName: "時間/円",
      },
      {
        costItemCd: "C1005",
        costItemName: "間接費",
        costItemDetailCd: "CI0012",
        costItemDetailName: "運賃収入割合",
        unitCd: "6",
        unitPrice: "0.04",
        unitName: "%",
      },
      {
        costItemCd: "C9000",
        costItemName: "小計",
        cost: "233333",
      },
    ],
    // ConfirmDialog
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // SimpleDialog
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 有効期限設定モーダル
    isOpenEditExpireDateDialog: false,
    selectedExpireDateList: [],
    inputList: [],
    selectedIndex: 0,
    deleteList: [],
    fiveInDiv: "",
    updateDialogMessage: false,
    inValidExpireDateList: [],
  }),
  methods: {
    showVariableCostDetail() {
      this.headers.splice(0);
      this.headers.push(...this.baseHeaders);
      this.headers.push(this.variableCostHeaders);

      this.items.splice(0);
      this.items.push(...this.variableCostDetails);

      this.currentCostDiv = this.costDivName10;
      console.log(this.currentCostDiv);
    },
    showFixedCostDetail() {
      this.headers.splice(0);
      this.headers.push(...this.baseHeaders);
      this.headers.push(this.fixedCostHeaders);

      this.items.splice(0);
      this.items.push(...this.fixedCostDetails);

      this.currentCostDiv = this.costDivName20;
      console.log(this.currentCostDiv);
    },
    isVariableCostSelected() {
      return this.currentCostDiv === this.costDivName10;
    },
    isFixedCostSelected() {
      return this.currentCostDiv === this.costDivName20;
    },
    getVariableCostTdClass() {
      const classes = [];
      if (this.isVariableCostSelected()) {
        classes.push("cost-selected");
      }
      return classes;
    },
    getFixedCostTdClass() {
      const classes = [];
      if (this.isFixedCostSelected()) {
        classes.push("cost-selected");
      }
      return classes;
    },
    // 初期値
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.showVariableCostDetail();
    },
    // 有効期限設定モーダルの表示
    // editExpireDate(item, index) {
    //   item.expireDateList[0].unitPrice = item.parentUnitPrice;
    //   this.selectedExpireDateList = item.expireDateList;
    //   this.selectedIndex = index;
    //   this.isOpenEditExpireDateDialog = true;
    // },
    editExpireDate() {
      this.isOpenEditExpireDateDialog = true;
    },

    parentMethod: function (deleteList, isConfig, target) {
      this.deleteList = deleteList;
      if (isConfig) {
        this.updateDialogMessage = false;
        this.inValidExpireDateList.splice(this.inValidExpireDateList.indexOf(target), 1);
        // target.isValidSumPrice = this.isValidSumPrice(
        //   target.achieveQuantity,
        //   target.unitPrice,
        //   target.convertedValue
        // );
      }
    },

    // isValidSumPrice(achieveQuantity, unitPrice, convertedValue) {},
    isValidSumPrice() {},

    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // コピー
    copy() {},

    // 登録確認
    displayRegistrationConfirmationDialog() {
      this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.registerInfo;
    },
    // 登録
    registerInfo() {
      this.displayRegistrationCompletionDialog();
    },
    // 登録完了
    displayRegistrationCompletionDialog() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = i18n.tc("label.lbl_result");
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
.cost-div-name {
  font-size: large;
  font-weight: bold;
}
.detail-table {
  width: 100%;
  border-collapse: collapse;
  border-color: black;
  table-layout: fixed;
}
.detail-table th {
  background-color: #effad9;
  font-size: large;
}
.detail-table th p {
  margin: 0;
}
.detail-th-sub {
  font-size: small;
}
.detail-table td {
  font-size: large;
  padding: 8px;
  text-align: center;
}
.detail-tf input {
  text-align: right;
}
.cost-selected {
  background-color: #fff2cc;
}
.cost-tr-subtotal {
  background-color: #deebf7;
}
</style>
<style lang="scss" scoped>
#costData {
  &.v-data-table--fixed-header ::v-deep {
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    td {
      font-size: large;
    }
  }
}
</style>
