<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <!-- 運送依頼受付簿作成 -->
      <NavBar tittle="label.lbl_carriageRequestReceptionOutput" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <div :if="searchErrorMessage" id="searchError">
            {{ searchErrorMessage }}
          </div>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 出発日(From)-->
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="dptDateFrom"
                :label="$t('label.lbl_departureDateFrom')"
                @change="changeDateFrom($event, 'departure')"
                :rules="[rules.inputRequiredCreate(dptDateFrom, dptDateTo), rules.yyyymmdd_create]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="dptDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dptDateFromCal"
                  @input="dptDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="changeOneDayFrom('departure')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="changeOneDayFrom('departure', 'add')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出発日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="dptDateTo"
                :label="$t('label.lbl_departureDateTo')"
                @change="changeDateTo($event, 'departure')"
                :rules="[rules.inputRequiredCreate(dptDateTo, dptDateFrom), rules.yyyymmdd_create]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="dptDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dptDateToCal"
                  @input="dptDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="changeOneDayTo('departure')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="changeOneDayTo('departure', 'add')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>
            <!-- 着日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrDateFrom"
                :label="$t('label.lbl_arrivalPackDateFrom')"
                @change="changeDateFrom($event, 'arrival')"
                :rules="[rules.inputRequiredCreate(arrDateFrom, arrDateTo), rules.yyyymmdd_create]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="arrDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrDateFromCal"
                  @input="arrDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="changeOneDayFrom('arrival')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="changeOneDayFrom('arrival', 'add')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 着日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrDateTo"
                :label="$t('label.lbl_arrivalPackDateTo')"
                @change="changeDateTo($event, 'arrival')"
                :rules="[rules.inputRequiredCreate(arrDateTo, arrDateFrom), rules.yyyymmdd_create]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="arrAlertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="arrDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrDateToCal"
                  @input="arrDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="changeOneDayTo('arrival')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="changeOneDayTo('arrival', 'add')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :search-input.sync="supplierInput"
                @change="changeSupplier"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row style="padding-top: 10px">
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area" style="float: right">
                <!-- 運送依頼受付簿出力ボタン-->
                <v-btn color="primary" class="other-btn-nosize download-btn" @click="getReport()">
                  {{ $t("btn.btn_requestReceptionOutput") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="carriageSearchList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="510px"
          :options.sync="sortOptions"
        >
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_TMF_001,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_100: false,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    supplierHint: "",
    // 出発日
    dptDateFrom: "",
    dptDateFromMenu: false,
    dptDateFromCal: "",
    dptDateTo: "",
    dptDateToMenu: false,
    dptDateToCal: "",
    // 着日
    arrDateFrom: "",
    arrDateFromCal: "",
    arrDateFromMenu: false,
    arrDateTo: "",
    arrDateToCal: "",
    arrDateToMenu: false,
    // 表示件数
    totalCount: "",
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 表示件数切替ボタン
    itemsPerPageBtn: "100",
    carriageSearchList: [],
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 初期データ
    defaultData: [],
    headerItems: [
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
        class: "product-fixed2",
      },
      // 出発日
      {
        text: i18n.tc("label.lbl_departureDate"),
        value: "dptDate",
        width: "160px",
        align: "left",
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "200px",
        align: "left",
      },
      // 集荷元
      {
        text: i18n.tc("label.lbl_pickupPoint"),
        value: "pickupPoint",
        width: "200px",
        align: "left",
      },
      // 配達先
      {
        text: i18n.tc("label.lbl_deliveryPoint"),
        value: "deliveryPoint",
        width: "200px",
        align: "left",
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "200px",
        align: "left",
      },
      // 所属
      {
        text: i18n.tc("label.lbl_belongingOffice"),
        value: "belongingOffice",
        width: "200px",
        align: "left",
      },
      // 号車
      {
        text: i18n.tc("label.lbl_vehicleCode"),
        value: "vehicleCode",
        width: "200px",
        align: "left",
      },
      // 乗務員
      {
        text: i18n.tc("label.lbl_driver"),
        value: "driver",
        width: "200px",
        align: "left",
      },
      // 売上金額
      {
        text: i18n.tc("label.lbl_salesAmount"),
        value: "salesAmount",
        width: "170px",
        align: "right",
      },
      // 支払金額
      {
        text: i18n.tc("label.lbl_paymentAmount"),
        value: "paymentAmount",
        width: "170px",
        align: "right",
      },
      // 着日
      {
        text: i18n.tc("label.lbl_arrivalPackDate"),
        value: "arrDate",
        width: "200px",
        align: "left",
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        width: "200px",
        align: "left",
      },
    ],
    // メニュー
    openMenu: null,
    supplierInput: "",
    getOfficeSelected: "",
    getPickupSelected: "",
    getDeliverySelected: "",
    updateDialogMessage: "",
    backMessage: "",
    searchParam: {
      supplierCd: "",
      dptDateFrom: "",
      dptDateTo: "",
      arrDateFrom: "",
      arrDateTo: "",
    },
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    arrAlertMessage: "",
    searchErrorMessage: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
  }),

  methods: {
    link() {
      location.href = this.returnURL;
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },

    /**
     * 取引先取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const params = {};
      // 取引先コード（前方一致）
      params.supplierCode = val;

      const clientList = getParameter.getTmsBizCustomerMst(params);
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 検索ボタン押下処理
     */
    getApiList(isSearchConditionUpdate) {
      this.searchErrorMessage = "";
      // 出発日、着日どちらか1つ以上設定されている場合
      if (this.checkParams()) {
        // 入力チェック結果フラグ
        let inputCheckResult = false;
        let check = 0;
        // メッセージ初期化
        this.alertMessage = "";
        this.arrAlertMessage = "";
        // バリデーションチェックを行う。
        inputCheckResult = this.$refs.form.validate();
        if (inputCheckResult) {
          // 絞り込み条件の相関チェック
          check = this.checkSearchConditions();
        }

        if (inputCheckResult && check == 0) {
          this.$refs.form.resetValidation();
          // 画面遷移フラグをfalseに戻す
          this.checkFlg = false;

          if (inputCheckResult) {
            // ローディング画面表示ON
            this.loadingCounter = 1;

            // バリデーションエラーメッセージを非表示
            this.alertMessage = "";
            this.arrAlertMessage = "";

            // httpClientで使用する共通IFパラメータ等を作成
            const config = this.$httpClient.createGetApiRequestConfig();
            // 検索条件のパラム作成
            // 取引先
            if (this.suppliersSelected) {
              config.params.supplierCd = this.suppliersSelected;
            }
            // 発注日From
            if (this.dptDateFrom) {
              config.params.dptDateFrom = this.dptDateFrom;
            }
            // 発注日To
            if (this.dptDateTo) {
              config.params.dptDateTo = this.dptDateTo;
            }
            // 集荷予定日From
            if (this.arrDateFrom) {
              config.params.arrDateFrom = this.arrDateFrom;
            }
            // 集荷予定日To
            if (this.arrDateTo) {
              config.params.arrDateTo = this.arrDateTo;
            }
            // ページャー処理
            if (isSearchConditionUpdate) {
              // 検索ボタン押下時
              config.params.reqComPageIndex = "1";
              this.page = 1;
            } else {
              config.params.reqComPageIndex = this.page;
            }
            // ページング
            config.params.reqComPaginationFlg = "1";
            config.params.reqComPageLimit = this.itemsPerPage;
            if (this.sortItem != "") {
              config.params.reqComSortItem = this.sortItem;
            }

            return new Promise((resolve, reject) => {
              this.$httpClient
                // 接続先のAPI_URLを入力
                .secureGet(appConfig.API_URL.BIZ_CARRIAGE_REQUEST_SEARCHE, config)
                .then((response) => {
                  const jsonData = JSON.parse(JSON.stringify(response.data));

                  // 成功
                  if (jsonData.resCom.resComCode == "000") {
                    this.pageCount = jsonData.resCom.resComPagination.totalPage;
                    this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                    const list = [];
                    // 運送依頼受付簿リストを取得
                    jsonData.resIdv.carriageSearchList.carriageSearch.forEach((row) => {
                      list.push({
                        No:
                          jsonData.resIdv.carriageSearchList.carriageSearch.indexOf(row) +
                          1 +
                          this.itemsPerPage * (this.page - 1),
                        // 出発日
                        dptDate: row.dptDate.substr(0, 10),
                        // 取引先
                        supplier: row.supplier,
                        // 集荷元
                        pickupPoint: row.pickupPoint,
                        // 配達先
                        deliveryPoint: row.deliveryPoint,
                        // 品名
                        productName: row.productName,
                        // 所属
                        belongingOffice: row.belongingOffice,
                        // 社号
                        vehicleCode: row.vehicleCode,
                        // 乗務員
                        driver: row.driver,
                        // 売上金額
                        salesAmount: commonFunction.getDelimiter(row.salesAmount, 9),
                        // 支払金額
                        paymentAmount: commonFunction.getDelimiter(row.paymentAmount, 9),
                        // 着日
                        arrDate: row.arrDate.substr(0, 10),
                        // 備考
                        remarks: row.remarks,
                      });
                    });
                    this.carriageSearchList = list;
                    resolve(response);
                  } else {
                    // エラー時
                    this.infoDialog.message = jsonData.resCom.resComMessage;
                    this.infoDialog.title = appConfig.DIALOG.title;
                    this.infoDialog.isOpen = true;
                    this.infoDialog.firstPageFlag = true;
                    reject();
                  }
                })
                .catch((ex) => {
                  this.infoDialog.message = ex;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                })
                .finally(() => {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                });
            });
          }
        }
      } else {
        this.searchErrorMessage = i18n.tc("check.chk_inputOneOrMore", 0, [
          i18n.tc("label.lbl_departureDate"),
          i18n.tc("label.lbl_arrivalPackDate"),
        ]);
      }
    },

    checkSearchConditions() {
      // 入力チェック結果フラグ
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.arrAlertMessage = "";
      // 出発日FromToの日付、入力チェック
      if (this.dptDateFrom && this.dptDateTo) {
        if (new Date(this.dptDateFrom).getTime() > new Date(this.dptDateTo).getTime()) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      // 着日FromToの日付、入力チェック
      if (this.arrDateFrom && this.arrDateTo) {
        if (new Date(this.arrDateFrom).getTime() > new Date(this.arrDateTo).getTime()) {
          this.arrAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      return check;
    },

    /**
     *  絞り込み条件のパラムチェック
     */
    checkParams() {
      // 出発日、着日の値すべて入力されていない場合、falseを返す
      if (!this.dptDateFrom && !this.dptDateTo && !this.arrDateFrom && !this.arrDateTo) {
        return false;
      }
      return true;
    },
    /**
     *  絞り込み条件のパラム作成
     */
    createParams() {
      const params = {};
      // 取引先
      if (this.suppliersSelected) {
        params.supplierCd = this.suppliersSelected;
        // 取引先の文字列（コード＋名称）をパラメータに設定
        const val = this.supplierList.find((v) => v.value == this.suppliersSelected);
        params.supplierText = val.text;
      }
      // 発注日From
      if (this.dptDateFrom) {
        params.dptDateFrom = this.dptDateFrom;
      }
      // 発注日To
      if (this.dptDateTo) {
        params.dptDateTo = this.dptDateTo;
      }
      // 集荷予定日From
      if (this.arrDateFrom) {
        params.arrDateFrom = this.arrDateFrom;
      }
      // 集荷予定日To
      if (this.arrDateTo) {
        params.arrDateTo = this.arrDateTo;
      }
      return params;
    },

    /**
     *  運送依頼受付簿発行API
     */
    getReport() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.searchErrorMessage = "";
      // 検索条件が1つ以上設定されている場合
      if (this.checkParams()) {
        // 入力チェック結果フラグ
        let inputCheckResult = false;
        let check = 0;
        // メッセージ初期化
        this.alertMessage = "";
        this.arrAlertMessage = "";
        // バリデーションチェックを行う。
        inputCheckResult = this.$refs.form.validate();
        if (inputCheckResult) {
          // 絞り込み条件の相関チェック
          check = this.checkSearchConditions();
        }

        if (inputCheckResult && check == 0) {
          this.$refs.form.resetValidation();
          // httpClientで使用する共通IFパラメータ等を作成する。
          const body = this.$httpClient.createRequestBodyConfig();
          // 共通IF項目 画面ID
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMF_001;
          // 絞り込み条件のパラム取得
          body.reqIdv = this.createParams();
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(
                appConfig.API_URL.BIZ_CARRIAGE_REQUEST_RECEPTION,
                body,
                appConfig.APP_CONFIG
              )
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                  this.returnURL = jsonData.resIdv.fileUrl;
                  this.link();
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      } else {
        this.searchErrorMessage = i18n.tc("check.chk_inputOneOrMore", 0, [
          i18n.tc("label.lbl_departureDate"),
          i18n.tc("label.lbl_arrivalPackDate"),
        ]);
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.suppliersSelected = "";
      this.dptDateFrom = dateTimeHelper.convertJST().substr(0, 10);
      this.dptDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.dptDateTo = dateTimeHelper.convertJST().substr(0, 10);
      this.dptDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.arrDateFrom = "";
      this.arrDateFromCal = "";
      this.arrDateTo = "";
      this.arrDateToCal = "";
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 日付Fromを－１日、＋１日する
     */
    changeOneDayFrom(param, changetime = "") {
      switch (param) {
        case "departure":
          this.dptDateFromCal = commonFunction.addDate(this.dptDateFromCal, changetime);
          break;
        case "arrival":
          this.arrDateFromCal = commonFunction.addDate(this.arrDateFromCal, changetime);
          break;
      }
    },

    /**
     * 日付Toを－１日します。
     */
    changeOneDayTo(param, changetime = "") {
      switch (param) {
        case "departure":
          this.dptDateToCal = commonFunction.addDate(this.dptDateToCal, changetime);
          break;
        case "arrival":
          this.arrDateToCal = commonFunction.addDate(this.arrDateToCal, changetime);
          break;
      }
    },

    /**
     * カレンダーコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val, param) {
      // 入力された日付をカレンダーコンポーネント用に変換
      const formatedDt = this.formatInputDate(val);

      switch (param) {
        case "departure":
          this.dptDateFromCal = formatedDt;
          break;
        case "arrival":
          this.arrDateFromCal = formatedDt;
          break;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val, param) {
      // 入力された日付をカレンダーコンポーネント用に変換
      const formatedDt = this.formatInputDate(val);

      switch (param) {
        case "departure":
          this.dptDateToCal = formatedDt;
          break;
        case "arrival":
          this.arrDateToCal = formatedDt;
          break;
      }
    },

    /**
     * 入力された日付をyyyy-MM-ddに変換して返す
     */
    formatInputDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt =
          inputVal.substring(0, 4) +
          "/" +
          inputVal.substring(4, 6) +
          "/" +
          inputVal.substring(6, 9);
      } else {
        strDt =
          inputVal.substring(0, 4) +
          "/" +
          inputVal.substring(5, 7) +
          "/" +
          inputVal.substring(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substring(0, 10)));
      }
      return formatedDt;
    },

    /**
     * 取引先 ヒント文字列取得処理
     */
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },

    /**
     * 取引先 チェンジイベント
     */
    changeSupplier(value) {
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.supplierHint = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.supplierHint = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.alertMessage = "";
      this.arrAlertMessage = "";
    },
  },

  watch: {
    /**
     * 取引先が編集された際、他の拠点選択の値をクリアする
     */
    // 現時点で取引先との紐づきがないためコメントアウト
    // suppliersSelected(newValue, oldValue) {
    //   // 輸送担当営業所/ 集荷元/ 配達先クリア
    //   if (oldValue != null && oldValue != "") {
    //     this.officeList = [];
    //     this.officeSelected = "";
    //     this.pickupPointList = [];
    //     this.pickupPointSelected = "";
    //     this.deliveryPointList = [];
    //     this.deliveryPointSelected = "";
    //   }
    // },

    dptDateFromCal: {
      handler(val) {
        this.dptDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    dptDateToCal: {
      handler(val) {
        this.dptDateTo = this.formatDate(val);
      },
      deep: true,
    },
    arrDateFromCal: {
      handler(val) {
        this.arrDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    arrDateToCal: {
      handler(val) {
        this.arrDateTo = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_TMF_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false);
        }
      },
      deep: true,
    },
    /**
     * 輸送担当営業所取得
     */
    getOfficeSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.officeList = [];
        //   this.officeSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 取引先コード
          // params.customerCode = this.suppliersSelected;
          // 拠点管理種別（2=営業所を取得）
          params.baseOwnerType = "2";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          const officeListTmp = getParameter.getTmsBizBaseMst(params);

          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.officeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },
    /**
     * 集荷元取得
     */
    getPickupSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.pickupPointList = [];
        //   this.pickupPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 取引先コード
          // params.customerCode = this.suppliersSelected;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          const pickupListTmp = getParameter.getTmsBizBaseMst(params);
          Promise.all([pickupListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.pickupPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    /**
     * 配達先取得
     */
    getDeliverySelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 取引先コード
          // params.customerCode = this.suppliersSelected;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
          Promise.all([deliveryListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.deliveryPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    supplierInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.supplierHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}

.add-textbox-remark {
  width: 40rem;
}

#searchError {
  color: rgb(255, 82, 82) !important;
}
</style>
